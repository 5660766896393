import { Theme } from "baseui";

export const STYLES = {
  noScrollbarWrapper: (theme: Theme, wrapper: object) => ({
    ...wrapper,
    ["::-webkit-scrollbar"]: {
      display: "none" /* Safari and Chrome */,
    },
    paddingBottom: "0",
  }),
  wrapper: (theme: Theme) =>
    ({
      alignItems: "center",
      gap: theme.sizing.scale300,
      maxWidth: "100%",
      overflowX: "scroll",
      paddingBottom: theme.sizing.scale600,
    }) as const,
};
