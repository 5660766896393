import { Theme } from "baseui";

export const WIDGET_FULL_WIDTH_OVERRIDE = {
  Root: {
    style: {
      width: "100%",
    },
  },
};

export const BUTTON_FULL_WIDTH_OVERRIDE = {
  BaseButton: {
    style: {
      width: "100%",
    },
  },
};

export const STATE_MENU_TABLE_ACTIONS_OVERRIDE = {
  List: {
    style: {
      [":focus"]: {
        outline: "none",
      },
      boxShadow: "none",
      padding: "0px",
    },
  },
  Option: {
    props: {
      overrides: {
        ListItem: {
          style: ({ $theme }: { $theme: Theme }) => ({
            [":hover"]: {
              background: $theme.colors.gray50,
            },
            alignItems: "center",
            backgroundColor: "transparent",
            color: $theme.colors.contentPrimary,
            display: "flex",
            height: $theme.sizing.scale1200,
          }),
        },
      },
    },
  },
};

export const POPOVER_TABLE_ACTIONS_OVERRIDE = {
  Arrow: {
    style: ({ $theme }: { $theme: Theme }) => ({
      backgroundColor: $theme.colors.white,
      outline: `${$theme.colors.white} solid`,
    }),
  },
  Body: {
    style: ({ $theme }: { $theme: Theme }) => ({
      backgroundColor: $theme.colors.white,
      padding: $theme.sizing.scale600,
    }),
  },
};

export const POPOVER_NOTIFICATIONS_WIDGET_OVERRIDE = {
  Arrow: {
    style: ({ $theme }: { $theme: Theme }) => ({
      backgroundColor: $theme.colors.white,
      outline: `${$theme.colors.white} solid`,
    }),
  },
  Body: {
    style: ({ $theme }: { $theme: Theme }) => ({
      backgroundColor: $theme.colors.white,
    }),
  },
};

export const DRAWER_TABLE_ACTIONS_OVERRIDE = {
  DrawerContainer: {
    style: ({ $theme }: { $theme: Theme }) => ({
      borderTopLeftRadius: $theme.sizing.scale300,
      borderTopRightRadius: $theme.sizing.scale300,
    }),
  },
};

export const TABS_HIGHLIGHT_OVERRIDE = {
  TabHighlight: {
    style: ({ $theme }: { $theme: Theme }) => ({
      backgroundColor: $theme.colors.buttonPrimaryFill,
    }),
  },
};
