import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { Button } from "baseui/button";
import { StyledAction, StyledBody } from "baseui/card";
import { FormControl } from "baseui/form-control";
import { Input } from "baseui/input";
import { toaster } from "baseui/toast";
import { HeadingSmall, ParagraphSmall } from "baseui/typography";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";

import { API } from "../../api";
import { BUTTON_FULL_WIDTH_OVERRIDE } from "../../components/common-overrides";
import Loading from "../../components/loading";
import MasterPage from "../../components/master-page";
import { AuthContext } from "../../providers/auth-provider";
import { STYLES } from "./styles";

const Login = (): React.ReactNode => {
  const [css, theme] = useStyletron();
  const navigate = useNavigate();
  const { t: translate } = useTranslation();
  const authContext = React.useContext(AuthContext);
  const location = useLocation();
  const [isLoadingAutoLogin, setIsLoadingAutoLogin] = React.useState(
    Boolean(authContext.state.accessToken && !authContext.state.isLoggedIn),
  );
  const [isLoading, setIsLoading] = React.useState(false);
  const [form, setForm] = React.useState({
    login: "",
    password: "",
  });
  const { redirectUrl } = location.state || {};

  React.useEffect(() => {
    if (authContext.isLoggedIn()) {
      navigate(redirectUrl || "/home", { replace: true });
    }
  }, [authContext, navigate]);

  React.useEffect(() => {
    if (!authContext.isLoggedIn()) {
      authContext
        .tryAutoLogin((started: boolean) => {
          setIsLoadingAutoLogin(started);
        })
        .then(({ fetchingAutoLogin, loggedIn }) => {
          if (!fetchingAutoLogin) {
            setIsLoadingAutoLogin(loggedIn);
          }
        });
    }
  }, [authContext]);

  const onSubmit = React.useCallback(async () => {
    setIsLoading(true);
    const result = await API.login(form);
    if (result?.accessToken && result?.refreshToken) {
      const status = authContext.persist(result);
      if (status) {
        navigate(redirectUrl || "/home", { replace: true });
      }
    } else if (result?.logged === false && result?.msg) {
      toaster.negative(result?.msg);
    }
    setIsLoading(false);
  }, [form, navigate, authContext]);

  return (
    <MasterPage hasSidebar={false}>
      <Block className={css(STYLES.container(theme, isLoadingAutoLogin))}>
        <Loading isLoading={isLoadingAutoLogin}>
          <StyledBody>
            <HeadingSmall marginBottom={0} style={{ textAlign: "center" }}>
              {translate("login.welcome")}
            </HeadingSmall>
            <ParagraphSmall style={{ textAlign: "center" }}>
              {translate("login.subtitle")}
            </ParagraphSmall>
          </StyledBody>
          <StyledAction>
            <FormControl label={() => translate("login.key")}>
              <Input
                onChange={(e) =>
                  setForm((form) => ({ ...form, login: e.target.value }))
                }
                value={form.login}
              />
            </FormControl>
            <FormControl label={() => translate("login.password")}>
              <Input
                onChange={(e) =>
                  setForm((form) => ({ ...form, password: e.target.value }))
                }
                type="password"
                value={form.password}
              />
            </FormControl>
            <Button
              isLoading={isLoading}
              onClick={onSubmit}
              overrides={BUTTON_FULL_WIDTH_OVERRIDE}
            >
              {translate("login.submit")}
            </Button>
          </StyledAction>
        </Loading>
      </Block>
    </MasterPage>
  );
};
export default Login;
