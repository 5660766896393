import { useStyletron } from "baseui";
import { Tile } from "baseui/tile";
import { ParagraphSmall } from "baseui/typography";
import * as React from "react";

import {
  CampaignAllowListT,
  useCampaignAllowList,
} from "../../../hooks/use-campaign-allow-list";
import { WIDGET_FULL_WIDTH_OVERRIDE } from "../../common-overrides";
import ConditionalSlider from "../../conditional-slider";
import { Row } from "../../containers";
import { STYLES } from "./styles";

export type WidgetItem = CampaignAllowListT & {
  itemId: string;
  subtitle: string;
  title: string;
};

export const Widgets = ({
  items,
  onChange,
}: {
  items: Array<WidgetItem>;
  onChange: ({ item }: { item: WidgetItem }) => void;
}): React.ReactNode => {
  const [css, theme] = useStyletron();
  const campaignAllowListFilter = useCampaignAllowList();

  const filteredItems = items.filter(campaignAllowListFilter);
  if (!filteredItems.length) {
    return null;
  }

  return (
    <Row className={css(STYLES.wrapper(theme))}>
      <ConditionalSlider
        condition={filteredItems.length > 1}
        hideScrollbar={true}
      >
        {filteredItems.map((item: WidgetItem, index: number) => (
          <Tile
            key={`widget:${index}`}
            label={item.title}
            onClick={() => onChange({ item })}
            overrides={
              filteredItems.length === 1
                ? WIDGET_FULL_WIDTH_OVERRIDE
                : undefined
            }
            tileKind={"action"}
          >
            <ParagraphSmall color="#4B4B4B" margin={0}>
              {item.subtitle}
            </ParagraphSmall>
          </Tile>
        ))}
      </ConditionalSlider>
    </Row>
  );
};
