import { useStyletron } from "baseui";
import { Button, KIND, SIZE } from "baseui/button";
import { ListItem } from "baseui/list";
import { PLACEMENT, StatefulPopover } from "baseui/popover";
import { LabelMedium, ParagraphSmall } from "baseui/typography";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import { ApiContext } from "../../providers/api-provider";
import {
  BUTTON_FULL_WIDTH_OVERRIDE,
  POPOVER_NOTIFICATIONS_WIDGET_OVERRIDE,
} from "../common-overrides";
import { Column, Row } from "../containers";
import EmptyState from "../empty-state";
import { NotificationDotIcon, NotificationIcon } from "../icons";
import If from "../if";
import Loading from "../loading";
import { STYLES } from "./styles";

export const NotificationWidget = (): React.ReactNode => {
  const [css, theme] = useStyletron();
  const { t: translate } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const apiContext = React.useContext(ApiContext);

  const hasNewNotifications = React.useCallback(() => {
    const { notifications } = apiContext;
    if (!notifications) {
      return false;
    }
    return Boolean(
      notifications.filter(
        (notification) => !notification.NotificationUsers[0]?.readDate,
      ).length,
    );
  }, [apiContext.notifications]);

  const onNotificationClick = React.useCallback(
    ({ close, id }: { close: () => void; id?: number }) => {
      const nextRoute = id ? `/notification/${id}` : "/notifications";
      if (location.pathname !== nextRoute) {
        navigate(nextRoute);
      }
      close();
    },
    [location],
  );

  const notificationListContent = React.useCallback(
    ({ close }: { close: () => void }) => {
      const { notifications } = apiContext;
      if (!notifications) {
        return (
          <Column className={css(STYLES.noContentContainer(theme))}>
            <Loading isLoading={true} />
          </Column>
        );
      }
      if (notifications.length <= 0) {
        return (
          <Column className={css(STYLES.noContentContainer(theme))}>
            <EmptyState isEmpty={true} />
          </Column>
        );
      }
      return (
        <Column className={css(STYLES.listContainerWrapper(theme))}>
          <ul className={css(STYLES.listContainer(theme))}>
            {notifications.map((notification) => (
              <ListItem
                key={`ntf-widget-${notification.id}`}
                onClick={() =>
                  onNotificationClick({ close, id: notification.id })
                }
              >
                <Row className={css(STYLES.contentRow(theme))}>
                  <Column className={css(STYLES.contentColumn)}>
                    <LabelMedium>{notification.title}</LabelMedium>
                    <ParagraphSmall
                      className={css(STYLES.contentParagraph(theme))}
                    >
                      {notification.resume}
                    </ParagraphSmall>
                  </Column>
                  <If condition={!notification.NotificationUsers[0]?.readDate}>
                    <NotificationDotIcon
                      color={theme.colors.buttonPrimaryFill}
                    />
                  </If>
                </Row>
              </ListItem>
            ))}
          </ul>
          <ListItem>
            <Button
              kind={KIND.tertiary}
              onClick={() => onNotificationClick({ close })}
              overrides={BUTTON_FULL_WIDTH_OVERRIDE}
              size={SIZE.compact}
            >
              {translate("notifications.see_all")}
            </Button>
          </ListItem>
        </Column>
      );
    },
    [apiContext.notifications, onNotificationClick],
  );

  return (
    <StatefulPopover
      autoFocus
      content={notificationListContent}
      overrides={POPOVER_NOTIFICATIONS_WIDGET_OVERRIDE}
      placement={PLACEMENT.bottomRight}
      returnFocus
      showArrow
    >
      <div className={css(STYLES.widgetEntryPointContainer)}>
        <If condition={hasNewNotifications()}>
          <div className={css(STYLES.dotContainer(theme))}>
            <NotificationDotIcon color={theme.colors.buttonPrimaryFill} />
          </div>
        </If>
        <Button kind={KIND.secondary}>
          <NotificationIcon />
        </Button>
      </div>
    </StatefulPopover>
  );
};
