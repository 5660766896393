export const settings = {
  aws: {
    s3: {
      BASE_URL: "https://s3.us-east-2.amazonaws.com/",
    },
  },
  login: {
    allowedCampaigns: ["uninter", "indicapravaler"],
  },
};
