import br from "./br";

export default {
  ...br,
  theme: {
    colors: {
      ...br.theme.colors,
    },
    custom: {
      ...br.theme.custom,
      backgroundColor: "#f0f0f0",
      footerColor: "#1B1B1B",
      foregroundColor: "#FFF",
      headerColor: "#FFF",
      navigationIconColor: "#149AFF",
      navigationSectionTitleColor: "#004579",
      navigationSelectedIndicatorColor: "#149AFF",
    },
  },
};
