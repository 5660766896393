import { Theme } from "baseui";

import { CustomTheme } from "../../../app/theme";

export const STYLES = {
  navigationIconWrapper: (theme: Theme) => ({
    alignItems: "center",
    justifyContent: "center",
    width: theme.sizing.scale800,
  }),
  navigationItemSelectedIndicator: (theme: Theme, isActive: boolean) =>
    ({
      background: isActive
        ? (theme as CustomTheme).custom.navigationSelectedIndicatorColor
        : "unset",
      height: "100%",
      left: "0",
      position: "absolute",
      top: "0",
      width: theme.sizing.scale100,
    }) as const,
  navigationItemWrapper: (theme: Theme) =>
    ({
      [":hover"]: {
        background: theme.colors.gray50,
      },
      cursor: "pointer",
      gap: theme.sizing.scale600,
      padding: `${theme.sizing.scale600} ${theme.sizing.scale700}`,
      position: "relative",
    }) as const,
  navigationSectionWrapper: (theme: Theme) => ({
    background: theme.colors.gray100,
    gap: theme.sizing.scale600,
    padding: `${theme.sizing.scale550} ${theme.sizing.scale600}`,
  }),
  navigationSeparator: (theme: Theme) => ({
    background: theme.colors.gray100,
    height: theme.sizing.scale100,
  }),
  wrapper: (theme: Theme) => ({
    paddingTop: theme.sizing.scale300,
  }),
};
