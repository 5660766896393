import * as React from "react";

import { AuthContext } from "../providers/auth-provider";

export type CampaignAllowListT = {
  excludedCampaigns?: Array<string>;
  includedCampaigns?: Array<string>;
};

export const useCampaignAllowList = () => {
  const authContext = React.useContext(AuthContext);
  const campaign = authContext.userState?.campaign || "";
  const campaignAllowListFilter = React.useCallback(
    (item: CampaignAllowListT) => {
      const excluded = item.excludedCampaigns;
      const included = item.includedCampaigns;
      if (excluded && included) {
        throw `You can't have included and excluded campaigns simultaneously. ${JSON.stringify(item)}`;
      }
      if (excluded?.length === 0 || included?.length === 0) {
        throw `You can't have included or excluded campaigns with an empty array. ${JSON.stringify(item)}`;
      }
      if (!excluded && !included) {
        return true;
      }
      const hasIncludedCampaigns = included?.length;
      const hasExcludedCurrentCampaign = excluded?.includes(campaign);
      const hasIncludedCurrentCampaign = included?.includes(campaign);
      if (hasExcludedCurrentCampaign) {
        return false;
      }
      if (hasIncludedCampaigns && hasIncludedCurrentCampaign) {
        return true;
      }
      if (hasIncludedCampaigns) {
        return false;
      }
      return true;
    },
    [campaign],
  );

  return campaignAllowListFilter;
};
