import axios from "axios";

import { BaseURL } from "..";
import { ShoppingItemRequestT, ShoppingItemT } from "./types";

export const getShoppingItems = async (
  body: ShoppingItemRequestT,
): Promise<{ items: Array<ShoppingItemT> }> => {
  return (await axios.post(`${BaseURL}/purchase/affiliate-shop/itens`, body))
    .data;
};

export const getShoppingItem = async (
  id: string,
): Promise<{ affiliateShop: ShoppingItemT }> => {
  return (await axios.get(`${BaseURL}/purchase/affiliate-shop/item/${id}`))
    .data;
};

export const buyShoppingItem = async (
  id: string,
  body: { observation?: string; quantity: number },
): Promise<{ affiliateShop: ShoppingItemT }> => {
  return (
    await axios.post(`${BaseURL}/purchase/affiliate-shop/buy/${id}`, body)
  ).data;
};
