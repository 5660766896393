import axios from "axios";
import { toaster } from "baseui/toast";
import * as React from "react";
import { useTranslation } from "react-i18next";
import Cookies from "universal-cookie";

import { API } from "../../api";
import { AuthT, BasicUserT } from "../../api/user/types";
import { settings } from "../../app/settings";
import { locales } from "../../locales";
import { AuthContextT, AuthResultT } from "./types";

const AUTH_EMPTY_OBJ: AuthResultT = {
  accessToken: undefined,
  isLoggedIn: false,
  refreshToken: undefined,
  trainingPageId: undefined,
};

export const AuthContext = React.createContext<AuthContextT>({
  destroy: () => undefined,
  getUserProfile: () => undefined,
  isLoggedIn: () => false,
  persist: () => false,
  state: AUTH_EMPTY_OBJ,
  tryAutoLogin: async () => ({ fetchingAutoLogin: false, loggedIn: false }),
  userState: undefined,
});

export const AuthProvider = ({ children }: { children?: React.ReactNode }) => {
  const { i18n } = useTranslation();
  const cookies = new Cookies();
  const [fetchingAutoLogin, setFetchingAutoLogin] = React.useState(false);
  const [state, setState] = React.useState<AuthResultT>({
    accessToken: cookies.get("_ser_at") || undefined,
    isLoggedIn: false,
    refreshToken: cookies.get("_ser_rt") || undefined,
    trainingPageId: undefined,
  });

  const [userState, setUserState] = React.useState<BasicUserT | undefined>(
    undefined,
  );

  // configure axios authorization
  axios.interceptors.request.use(function (config) {
    const cookies = new Cookies();
    const accessToken = cookies.get("_ser_at");
    if (accessToken) {
      config.headers.Authorization = `bearer ${accessToken}`;
    }
    return config;
  });

  // setting translation based on campaign
  React.useEffect(() => {
    const campaign = userState?.campaign;
    if (campaign) {
      const localeKeys = Object.keys(locales);
      const locale = `br_${campaign.replaceAll("-", "_")}`;
      if (localeKeys.includes(locale)) {
        i18n.changeLanguage(locale);
      }
    } else {
      i18n.changeLanguage("br");
    }
  }, [userState, locales, i18n]);

  const getCookieConfiguration = React.useCallback(() => {
    const secure = window.location.protocol === "https:";
    const { hostname } = window.location;
    let domain = undefined;
    switch (hostname) {
      case "lite.saberemrede.net":
        domain = ".saberemrede.net";
        break;
    }
    return {
      domain,
      expires: new Date(+new Date() + 3600 * 1000 * 24 * 365),
      path: "/",
      secure,
    };
  }, []);

  React.useEffect(() => {
    if (state && state.accessToken && state.refreshToken) {
      cookies.set("_ser_at", state.accessToken, getCookieConfiguration());
      cookies.set("_ser_rt", state.refreshToken, getCookieConfiguration());
    }
  }, [state]);

  const isLoggedIn = React.useCallback(() => {
    return state.isLoggedIn;
  }, [state]);

  const destroy = React.useCallback(() => {
    cookies.remove("_ser_at", getCookieConfiguration());
    cookies.remove("_ser_rt", getCookieConfiguration());
    setState(AUTH_EMPTY_OBJ);
    setUserState(undefined);
  }, []);

  const persist = React.useCallback(
    (authObj: AuthT) => {
      const { user } = authObj;
      if (
        user?.campaign &&
        settings.login.allowedCampaigns.includes(user.campaign)
      ) {
        setState({ ...authObj, isLoggedIn: true });
        setUserState(user);
        return true;
      } else {
        toaster.negative("A versão LITE está indisponível para sua campanha.");
        destroy();
        return false;
      }
    },
    [destroy],
  );

  const getUserProfile = React.useCallback(async () => {
    if (userState) {
      return userState;
    }
    const { user } = await API.myProfile();
    setUserState(user);
    return user as BasicUserT;
  }, [userState]);

  const tryAutoLogin = React.useCallback(
    async (onStart: (started: boolean) => void) => {
      try {
        if (fetchingAutoLogin) {
          return { fetchingAutoLogin: true, loggedIn: false };
        }
        if (state.accessToken && state.refreshToken) {
          setFetchingAutoLogin(true);
          onStart && onStart(true);
          const user = await getUserProfile();
          if (!user || !user.id) {
            setFetchingAutoLogin(false);
            return { fetchingAutoLogin: false, loggedIn: false };
          }
          const response = await API.login({
            login: (user.email || user.login) as string,
            password: state.refreshToken,
          });
          if (response.hasError) {
            setFetchingAutoLogin(false);
            return { fetchingAutoLogin: false, loggedIn: false };
          }
          setFetchingAutoLogin(false);
          return { fetchingAutoLogin: false, loggedIn: persist(response) };
        }
        setFetchingAutoLogin(false);
        return { fetchingAutoLogin: false, loggedIn: false };
      } catch {
        destroy();
        setFetchingAutoLogin(false);
        return { fetchingAutoLogin: false, loggedIn: false };
      }
    },
    [
      fetchingAutoLogin,
      state.accessToken,
      state.refreshToken,
      getUserProfile,
      persist,
      destroy,
    ],
  );

  return (
    <AuthContext.Provider
      value={{
        destroy,
        getUserProfile,
        isLoggedIn,
        persist,
        state,
        tryAutoLogin,
        userState,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
