import { AxiosError, isAxiosError } from "axios";
import { useStyletron } from "baseui";
import { Button } from "baseui/button";
import { FormControl } from "baseui/form-control";
import { Input } from "baseui/input";
import {
  Modal,
  ModalBody,
  ModalButton,
  ModalFooter,
  ModalHeader,
} from "baseui/modal";
import { Option, Select } from "baseui/select";
import { toaster } from "baseui/toast";
import {
  HeadingLarge,
  HeadingSmall,
  LabelMedium,
  LabelSmall,
} from "baseui/typography";
import parse from "html-react-parser";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { API, ValidationError } from "../../api";
import { ShoppingItemT } from "../../api/shopping/types";
import { settings } from "../../app/settings";
import { Column, DynamicFlex, Row } from "../../components/containers";
import EmptyState from "../../components/empty-state";
import If from "../../components/if";
import Loading from "../../components/loading";
import MasterPage from "../../components/master-page";
import { useScreenSize } from "../../hooks/use-screen-size";
import { STYLES } from "./styles";

const ShoppingItemDetail = (): React.ReactNode => {
  const { id: shoppingItemId } = useParams();
  const [css, theme] = useStyletron();
  const { t: translate } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState(false);
  const [item, setItem] = React.useState<ShoppingItemT | undefined>(undefined);
  const [quantity, setQuantity] = React.useState<Option | undefined>(undefined);
  const [observations, setObservations] = React.useState<string>("");
  const [isConfirmModalOpen, setIsConfirmModalOpen] = React.useState(false);
  const screenSize = useScreenSize();

  const isSectionEmpty = !isLoading && item === undefined;
  const isFullWidth = screenSize.width <= theme.breakpoints.small;

  const fetchShoppingItemsData = React.useCallback(async (id: string) => {
    setIsLoading(true);
    const { affiliateShop: item } = await API.getShoppingItem(id);
    setItem(item);
    setIsLoading(false);
  }, []);

  const confirmItemPurchase = React.useCallback(async () => {
    setIsConfirmModalOpen(false);
    if (!shoppingItemId || !quantity) {
      return;
    }
    try {
      await API.buyShoppingItem(shoppingItemId, {
        observation: observations,
        quantity: quantity?.id as number,
      });
      toaster.positive(translate("shopping_item_detail.confirm_success_msg"));
      navigate(`/shopping`, { replace: false });
    } catch (ex) {
      if (isAxiosError(ex)) {
        const error = ex as AxiosError;
        const data = error.response?.data;
        if (data) {
          toaster.negative((data as ValidationError).description);
        }
      }
    }
  }, [shoppingItemId, item, quantity, navigate]);

  React.useEffect(() => {
    if (shoppingItemId) {
      fetchShoppingItemsData(shoppingItemId);
    }
  }, [shoppingItemId]);

  const quantityOptions = React.useMemo(() => {
    const qty: Array<Option> = [];
    if (!item?.stock) {
      return qty;
    }
    for (let i = 1; i <= item?.stock; i++) {
      const option = { id: i, label: i.toString() };
      qty.push(option);
    }
    return qty;
  }, [item]);

  const totalPoints = React.useMemo(() => {
    if (!item) {
      return "-";
    }
    if (!quantity) {
      return item.promotionalPoints || item.points;
    }
    const points = item.promotionalPoints || item.points;
    return (quantity.id as number) * points;
  }, [quantity, item]);

  return (
    <MasterPage hasSidebar={true}>
      <React.Fragment>
        <Row className={css(STYLES.headingRow(theme))}>
          <HeadingSmall margin={0} style={{ fontWeight: "500" }}>
            {translate("shopping_item_detail.title")}
          </HeadingSmall>
        </Row>
      </React.Fragment>
      <Loading isLoading={isLoading}>
        <EmptyState isEmpty={isSectionEmpty}>
          <DynamicFlex flexDirection={isFullWidth ? "column" : "row"}>
            <Column className={css(STYLES.imageContainer(theme, isFullWidth))}>
              <img
                className={css(STYLES.img)}
                src={`${settings.aws.s3.BASE_URL}/${item?.image}`}
              />
            </Column>
            <Column className={css(STYLES.textContainer(theme))}>
              <HeadingLarge className={css(STYLES.heading(theme))}>
                {item?.name}
              </HeadingLarge>
              <LabelMedium color={theme.colors.gray600}>
                {translate("shopping_item_detail.points", {
                  points: item?.promotionalPoints || item?.points,
                })}
              </LabelMedium>
              <LabelMedium>{parse(item?.description || "")}</LabelMedium>
              <Row className={css(STYLES.formContainer(theme))}>
                <Column className={css(STYLES.formContainerChildren)}>
                  <FormControl
                    key={"select.quantity"}
                    label={translate("shopping_item_detail.quantity")}
                  >
                    <Select
                      onChange={(param) =>
                        setQuantity(param.option || undefined)
                      }
                      options={quantityOptions}
                      placeholder={translate(
                        "shopping_item_detail.quantity_placeholder",
                      )}
                      value={quantity ? [quantity] : []}
                    />
                  </FormControl>
                </Column>
                <If condition={Boolean(quantity)}>
                  <LabelMedium className={css(STYLES.formContainerChildren)}>
                    {translate("shopping_item_detail.total_points", {
                      points: totalPoints,
                    })}
                  </LabelMedium>
                </If>
              </Row>
              <Button
                className={css(STYLES.button(theme, isFullWidth))}
                disabled={!quantity}
                kind="primary"
                onClick={() => {
                  setObservations("");
                  setIsConfirmModalOpen(true);
                }}
                size="default"
              >
                {translate("shopping_item_detail.button")}
              </Button>
            </Column>
          </DynamicFlex>
        </EmptyState>
      </Loading>
      <Modal
        animate
        autoFocus
        closeable
        isOpen={isConfirmModalOpen}
        onClose={() => setIsConfirmModalOpen(false)}
        role={"dialog"}
        size={"default"}
      >
        <ModalHeader>
          {translate("shopping_item_detail.confirm_modal_title")}
        </ModalHeader>
        <ModalBody>
          <Column className={css(STYLES.modalContainer(theme))}>
            <LabelSmall>
              {translate("shopping_item_detail.confirm_modal_description", {
                item: item?.name as string,
                points: totalPoints,
                quantity: quantity?.id as number,
              })}
            </LabelSmall>
            <Column>
              <FormControl
                key={"input.observations"}
                label={translate("shopping_item_detail.observations")}
              >
                <Input
                  onChange={(e) => setObservations(e.target.value)}
                  placeholder={translate(
                    "shopping_item_detail.observations_placeholder",
                  )}
                  value={observations}
                />
              </FormControl>
            </Column>
          </Column>
        </ModalBody>
        <ModalFooter>
          <ModalButton onClick={() => confirmItemPurchase()}>
            {translate("shopping_item_detail.confirm_modal_button")}
          </ModalButton>
        </ModalFooter>
      </Modal>
    </MasterPage>
  );
};
export default ShoppingItemDetail;
