import { BaseProvider, createTheme, LightTheme, Theme } from "baseui";
import * as React from "react";
import { useTranslation } from "react-i18next";

export type CustomTheme = Theme & {
  custom: {
    backgroundColor: string;
    footerColor: string;
    foregroundColor: string;
    headerColor: string;
    navigationIconColor: string;
    navigationSectionTitleColor: string;
    navigationSelectedIndicatorColor: string;
  };
};

const theme = createTheme({
  breakpoints: {
    large: 992,
    medium: 768,
    small: 576,
  },
  colors: {
    buttonPrimaryFill: "#149AFF",
  },
  typography: Object.keys(LightTheme.typography).map((key: string) => {
    // @ts-expect-error typography type ts-error
    const typography = LightTheme.typography[key];
    if (key.startsWith("Display") || key.startsWith("Heading")) {
      typography.fontFamily = "Figtree";
    } else if (key.startsWith("Label") || key.startsWith("Paragraph")) {
      typography.fontFamily = "Open Sans";
    } else if (key.startsWith("Mono")) {
      typography.fontFamily = "Anonymous Pro";
    } else if (key.startsWith("font")) {
      const weight = parseInt("font100".replace("font", ""), 10);
      typography.fontFamily = weight > 450 ? "Figtree" : "Open Sans";
    }
    return typography;
  }),
});

const ThemeProvider = ({ children }: { children?: React.ReactNode }) => {
  const { i18n } = useTranslation();

  const defaultCustomTheme = i18n.getResourceBundle("br", "translation");
  const saltCustomTheme = i18n.getResourceBundle(i18n.language, "translation");

  const customTheme = {
    ...theme,
    colors: {
      ...theme.colors,
      ...defaultCustomTheme.theme.colors,
      ...(saltCustomTheme?.theme?.colors || {}),
    },
    custom: {
      ...defaultCustomTheme.theme.custom,
      ...(saltCustomTheme?.theme?.custom || {}),
    },
  };

  React.useEffect(() => {
    if (window) {
      const { body } = window.document;
      body.style.backgroundColor = (
        customTheme as CustomTheme
      ).custom.backgroundColor;
    }
  }, []);

  return <BaseProvider theme={customTheme}>{children}</BaseProvider>;
};

export default ThemeProvider;
