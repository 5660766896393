import * as React from "react";
import { Navigate, Route, Routes } from "react-router";
import { BrowserRouter } from "react-router-dom";

import Home from "../pages/home";
import Login from "../pages/login";
import { Notifications } from "../pages/notifications";
import Purchases from "../pages/purchases";
import Shopping from "../pages/shopping";
import ShoppingItemDetail from "../pages/shopping-item-detail";
import Training from "../pages/training";
import { AuthContext } from "../providers/auth-provider";

const PrivateRoute = ({
  privateElement,
}: {
  privateElement?: React.ReactNode;
}): React.ReactNode => {
  const authContext = React.useContext(AuthContext);
  const isLoggedIn = authContext.isLoggedIn();
  if (!isLoggedIn) {
    let redirectUrl = undefined;
    if (authContext.state.accessToken && window.location.pathname !== "/") {
      redirectUrl = `${window.location.pathname}${window.location.search}`;
    }
    return <Navigate replace={true} state={{ redirectUrl }} to="/" />;
  }
  return privateElement as React.JSX.Element;
};

const AppRoutes = () => (
  <BrowserRouter>
    <Routes>
      <Route element={<Login />} path="/" />
      <Route
        element={<PrivateRoute privateElement={<Home />} />}
        path="/home"
      />
      <Route
        element={<PrivateRoute privateElement={<Home />} />}
        path="/referral"
      />
      <Route
        element={<PrivateRoute privateElement={<Purchases />} />}
        path="/purchases"
      />
      <Route
        element={<PrivateRoute privateElement={<Home />} />}
        path="/commissions"
      />
      <Route
        element={<PrivateRoute privateElement={<Home />} />}
        path="/points"
      />
      <Route
        element={<PrivateRoute privateElement={<Home />} />}
        path="/profile"
      />
      <Route
        element={<PrivateRoute privateElement={<Home />} />}
        path="/challenges"
      />
      <Route
        element={<PrivateRoute privateElement={<Training />} />}
        path="/training"
      />
      <Route
        element={<PrivateRoute privateElement={<Shopping />} />}
        path="/shopping"
      />
      <Route
        element={<PrivateRoute privateElement={<ShoppingItemDetail />} />}
        path="/shopping-item-detail/:id"
      />
      <Route
        element={<PrivateRoute privateElement={<Home />} />}
        path="/ranking"
      />
      <Route
        element={<PrivateRoute privateElement={<Notifications />} />}
        path="/notifications"
      />
      <Route
        element={<PrivateRoute privateElement={<Notifications />} />}
        path="/notification/:id"
      />
    </Routes>
  </BrowserRouter>
);

export default AppRoutes;
