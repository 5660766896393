import axios from "axios";

import { BaseURL } from "..";
import { LoginRequestT } from "./types";

export const login = async ({ login, password }: LoginRequestT) => {
  const { data } = await axios.post(`${BaseURL}/apigateway/login`, {
    login,
    password,
  });
  return data;
};

export const myProfile = async () => {
  const { data } = await axios.get(`${BaseURL}/base/user`);
  return data;
};
