/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { default as i18next } from "i18next";
import { initReactI18next } from "react-i18next";

import { locales } from "../locales";

// check if every locale has exactly the same keys
const validateLocales = () => {
  const toEqualKeys = (
    obj1: { [x: string]: any },
    obj2: { [x: string]: any },
  ): boolean => {
    return Object.keys(obj1).every(
      (k) =>
        k in obj2 &&
        (typeof obj1[k] != "object" ||
          (typeof obj2[k] == "object" && toEqualKeys(obj1[k], obj2[k]))),
    );
  };

  for (const key of Object.keys(locales)) {
    if (key !== "br") {
      // @ts-expect-error keys matcher
      const rightSideCheck = toEqualKeys(locales["br"], locales[key]);
      if (!rightSideCheck) {
        throw `Key mismatch on locale "${key}" compared to "br"`;
      }
      // @ts-expect-error keys matcher
      const leftSideCheck = toEqualKeys(locales[key], locales["br"]);
      if (!leftSideCheck) {
        throw `Key mismatch on locale "br" compared to "${key}"`;
      }
    }
  }
  console.log("Locales validation complete");
};

if (process.env.NODE_ENV !== "production") {
  validateLocales();
}

i18next.use(initReactI18next).init({
  debug: false,
  fallbackLng: "br",
  interpolation: {
    escapeValue: false,
  },
  lng: "br",
  resources: {
    ...locales,
  },
});
