import * as React from "react";

type IconProps = {
  color?: string;
  height?: number;

  width?: number;
};

export const HomeIcon = ({
  color,
  height,
  width,
}: IconProps): React.ReactNode => {
  return (
    <svg
      fill="none"
      height={height || "18"}
      viewBox={`0 0 16 18`}
      width={width || "16"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 18V6L8 0L16 6V18H10V11H6V18H0Z" fill={color || "#149AFF"} />
    </svg>
  );
};

export const SchoolIcon = ({
  color,
  height,
  width,
}: IconProps): React.ReactNode => {
  return (
    <svg
      fill="none"
      height={height || "18"}
      viewBox="0 0 22 18"
      width={width || "22"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 14V7.1L11 12L0 6L11 0L22 6V14H20ZM11 18L4 14.2V9.2L11 13L18 9.2V14.2L11 18Z"
        fill={color || "#149AFF"}
      />
    </svg>
  );
};

export const MoneyIcon = ({
  color,
  height,
  width,
}: IconProps): React.ReactNode => {
  return (
    <svg
      fill="none"
      height={height || "18"}
      viewBox="0 0 10 18"
      width={width || "10"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.0252 18V15.85C3.14186 15.65 2.37953 15.2667 1.7382 14.7C1.09686 14.1333 0.625862 13.3333 0.325195 12.3L2.1752 11.55C2.4252 12.35 2.7962 12.9583 3.2882 13.375C3.7802 13.7917 4.42586 14 5.2252 14C5.90853 14 6.48786 13.846 6.9632 13.538C7.43853 13.23 7.67586 12.7507 7.6752 12.1C7.6752 11.5167 7.49186 11.0543 7.1252 10.713C6.75853 10.3717 5.90853 9.984 4.5752 9.55C3.14186 9.1 2.15853 8.56267 1.6252 7.938C1.09186 7.31333 0.825195 6.55067 0.825195 5.65C0.825195 4.56667 1.1752 3.725 1.8752 3.125C2.5752 2.525 3.29186 2.18333 4.0252 2.1V0H6.0252V2.1C6.85853 2.23333 7.5462 2.53767 8.0882 3.013C8.6302 3.48833 9.02586 4.06733 9.2752 4.75L7.4252 5.55C7.2252 5.01667 6.94186 4.61667 6.5752 4.35C6.20853 4.08333 5.70853 3.95 5.0752 3.95C4.34186 3.95 3.78353 4.11267 3.4002 4.438C3.01686 4.76333 2.8252 5.16733 2.8252 5.65C2.8252 6.2 3.0752 6.63333 3.5752 6.95C4.0752 7.26667 4.94186 7.6 6.1752 7.95C7.3252 8.28333 8.1962 8.81267 8.7882 9.538C9.3802 10.2633 9.67586 11.1007 9.67519 12.05C9.67519 13.2333 9.3252 14.1333 8.6252 14.75C7.9252 15.3667 7.05853 15.75 6.0252 15.9V18H4.0252Z"
        fill={color || "#149AFF"}
      />
    </svg>
  );
};

export const HistoryIcon = ({
  color,
  height,
  width,
}: IconProps): React.ReactNode => {
  return (
    <svg
      fill="none"
      height={height || "18"}
      viewBox="0 0 18 18"
      width={width || "18"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 18C6.9 18 5.04167 17.3627 3.425 16.088C1.80833 14.8133 0.758333 13.184 0.275 11.2C0.208333 10.95 0.258333 10.721 0.425 10.513C0.591667 10.305 0.816667 10.184 1.1 10.15C1.36667 10.1167 1.60833 10.1667 1.825 10.3C2.04167 10.4333 2.19167 10.6333 2.275 10.9C2.675 12.4 3.5 13.625 4.75 14.575C6 15.525 7.41667 16 9 16C10.95 16 12.6043 15.321 13.963 13.963C15.3217 12.605 16.0007 10.9507 16 9C15.9993 7.04933 15.3203 5.39533 13.963 4.038C12.6057 2.68067 10.9513 2.00133 9 2C7.85 2 6.775 2.26667 5.775 2.8C4.775 3.33333 3.93333 4.06667 3.25 5H5C5.28333 5 5.521 5.096 5.713 5.288C5.905 5.48 6.00067 5.71733 6 6C5.99933 6.28267 5.90333 6.52033 5.712 6.713C5.52067 6.90567 5.28333 7.00133 5 7H1C0.716667 7 0.479333 6.904 0.288 6.712C0.0966668 6.52 0.000666667 6.28267 0 6V2C0 1.71667 0.0960001 1.47933 0.288 1.288C0.48 1.09667 0.717333 1.00067 1 1C1.28267 0.999333 1.52033 1.09533 1.713 1.288C1.90567 1.48067 2.00133 1.718 2 2V3.35C2.85 2.28333 3.88767 1.45833 5.113 0.875C6.33833 0.291667 7.634 0 9 0C10.25 0 11.421 0.237667 12.513 0.713C13.605 1.18833 14.555 1.82967 15.363 2.637C16.171 3.44433 16.8127 4.39433 17.288 5.487C17.7633 6.57967 18.0007 7.75067 18 9C17.9993 10.2493 17.762 11.4203 17.288 12.513C16.814 13.6057 16.1723 14.5557 15.363 15.363C14.5537 16.1703 13.6037 16.812 12.513 17.288C11.4223 17.764 10.2513 18.0013 9 18ZM10 8.6L12.5 11.1C12.6833 11.2833 12.775 11.5167 12.775 11.8C12.775 12.0833 12.6833 12.3167 12.5 12.5C12.3167 12.6833 12.0833 12.775 11.8 12.775C11.5167 12.775 11.2833 12.6833 11.1 12.5L8.3 9.7C8.2 9.6 8.125 9.48767 8.075 9.363C8.025 9.23833 8 9.109 8 8.975V5C8 4.71667 8.096 4.47933 8.288 4.288C8.48 4.09667 8.71733 4.00067 9 4C9.28267 3.99933 9.52033 4.09533 9.713 4.288C9.90567 4.48067 10.0013 4.718 10 5V8.6Z"
        fill={color || "#149AFF"}
      />
    </svg>
  );
};

export const PersonIcon = ({
  color,
  height,
  width,
}: IconProps): React.ReactNode => {
  return (
    <svg
      fill="none"
      height={height || "16"}
      viewBox="0 0 16 16"
      width={width || "16"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 8C6.9 8 5.95833 7.60833 5.175 6.825C4.39167 6.04167 4 5.1 4 4C4 2.9 4.39167 1.95833 5.175 1.175C5.95833 0.391667 6.9 0 8 0C9.1 0 10.0417 0.391667 10.825 1.175C11.6083 1.95833 12 2.9 12 4C12 5.1 11.6083 6.04167 10.825 6.825C10.0417 7.60833 9.1 8 8 8ZM0 16V13.2C0 12.6333 0.146 12.1127 0.438 11.638C0.73 11.1633 1.11733 10.8007 1.6 10.55C2.63333 10.0333 3.68333 9.646 4.75 9.388C5.81667 9.13 6.9 9.00067 8 9C9.1 8.99933 10.1833 9.12867 11.25 9.388C12.3167 9.64733 13.3667 10.0347 14.4 10.55C14.8833 10.8 15.271 11.1627 15.563 11.638C15.855 12.1133 16.0007 12.634 16 13.2V16H0Z"
        fill={color || "#149AFF"}
      />
    </svg>
  );
};

export const HeadsetIcon = ({
  color,
  height,
  width,
}: IconProps): React.ReactNode => {
  return (
    <svg
      fill="none"
      height={height || "22"}
      viewBox="0 0 18 22"
      width={width || "18"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 0C6.61305 0 4.32387 0.948212 2.63604 2.63604C0.948211 4.32387 0 6.61305 0 9V16C0 17.66 1.34 19 3 19H6V11H2V9C2 5.13 5.13 2 9 2C12.87 2 16 5.13 16 9V11H12V19H16V20H9V22H15C16.66 22 18 20.66 18 19V9C18 6.61305 17.0518 4.32387 15.364 2.63604C13.6761 0.948212 11.3869 0 9 0Z"
        fill={color || "#149AFF"}
      />
    </svg>
  );
};

export const FilterIcon = ({
  color,
  height,
  width,
}: IconProps): React.ReactNode => {
  return (
    <svg
      fill="none"
      height={height || "10"}
      viewBox="0 0 16 10"
      width={width || "16"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.5 0.75H15.5V2.25H0.5V0.75ZM3 4.25H13V5.75H3V4.25ZM6 7.75H10V9.25H6V7.75Z"
        fill={color || "#1B1B1B"}
      />
    </svg>
  );
};

export const NotificationIcon = ({
  color,
  height,
  width,
}: IconProps): React.ReactNode => {
  return (
    <svg
      fill="none"
      height={height || "19"}
      viewBox="0 0 18 19"
      width={width || "18"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.9419 12.0581L15.25 10.3662V8.125C15.2481 6.57615 14.6719 5.08303 13.6329 3.93436C12.5939 2.7857 11.1659 2.06308 9.625 1.90625V0.625H8.375V1.90625C6.83411 2.06308 5.4061 2.7857 4.36711 3.93436C3.32811 5.08303 2.75194 6.57615 2.75 8.125V10.3662L1.05812 12.0581C0.940907 12.1753 0.875035 12.3343 0.875 12.5V14.375C0.875 14.5408 0.940848 14.6997 1.05806 14.8169C1.17527 14.9342 1.33424 15 1.5 15H5.875V15.4856C5.86113 16.2786 6.1405 17.0488 6.65954 17.6484C7.17858 18.2481 7.90073 18.635 8.6875 18.735C9.12199 18.7781 9.56068 18.7298 9.97537 18.5931C10.3901 18.4565 10.7716 18.2346 11.0953 17.9416C11.4191 17.6487 11.678 17.2913 11.8553 16.8923C12.0326 16.4933 12.1245 16.0616 12.125 15.625V15H16.5C16.6658 15 16.8247 14.9342 16.9419 14.8169C17.0592 14.6997 17.125 14.5408 17.125 14.375V12.5C17.125 12.3343 17.0591 12.1753 16.9419 12.0581ZM10.875 15.625C10.875 16.1223 10.6775 16.5992 10.3258 16.9508C9.97419 17.3025 9.49728 17.5 9 17.5C8.50272 17.5 8.02581 17.3025 7.67417 16.9508C7.32254 16.5992 7.125 16.1223 7.125 15.625V15H10.875V15.625ZM15.875 13.75H2.125V12.7587L3.81687 11.0669C3.93409 10.9497 3.99996 10.7907 4 10.625V8.125C4 6.79892 4.52678 5.52715 5.46447 4.58947C6.40215 3.65178 7.67392 3.125 9 3.125C10.3261 3.125 11.5979 3.65178 12.5355 4.58947C13.4732 5.52715 14 6.79892 14 8.125V10.625C14 10.7907 14.0659 10.9497 14.1831 11.0669L15.875 12.7587V13.75Z"
        fill={color || "black"}
      />
    </svg>
  );
};

export const NotificationDotIcon = ({
  color,
  height,
  width,
}: IconProps): React.ReactNode => {
  return (
    <svg
      fill="none"
      height={height || "12"}
      viewBox="0 0 12 12"
      width={width || "12"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="6"
        cy="6"
        fill={color || "#149AFF"}
        r="5"
        stroke="white"
        strokeWidth="2"
      />
    </svg>
  );
};
