import { Theme } from "baseui";

export const STYLES = {
  container: (theme: Theme, isAutoLogin: boolean) => ({
    margin: isAutoLogin ? `${theme.sizing.scale1600} auto` : "0 auto",
    maxWidth: "500px",
    padding: `${theme.sizing.scale600}`,
    width: `calc(100% - ${theme.sizing.scale900})`,
  }),
};
