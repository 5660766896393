import { Theme } from "baseui";

export const STYLES = {
  button: (theme: Theme) =>
    ({ marginTop: theme.sizing.scale600, width: "fit-content" }) as const,
  container: (theme: Theme) =>
    ({
      ...theme.borders.border300,
      borderRadius: theme.sizing.scale500,
      minWidth: "300px",
      overflow: "hidden",
      width: "calc(25% - 12px)",
    }) as const,
  content: (theme: Theme) =>
    ({
      display: "flex",
      flexDirection: "column",
      height: "100%",
      padding: theme.sizing.scale600,
    }) as const,
  heading: {
    "-webkit-box-orient": "vertical",
    "-webkit-line-clamp": "2",
    display: "-webkit-box",
    fontWeight: "500",
    margin: "0",
    maxHeight: "56px",
    overflow: "hidden",
    textOverflow: "ellipsis",
  } as const,
  img: (theme: Theme) =>
    ({
      aspectRatio: "16/9",
      borderBottomColor: theme.borders.border300.borderColor,
      borderBottomStyle: theme.borders.border300.borderStyle,
      borderBottomWidth: theme.borders.border300.borderWidth,
      objectFit: "cover",
      width: "100%",
    }) as const,
  paragraph: {
    "-webkit-box-orient": "vertical",
    "-webkit-line-clamp": "2",
    display: "-webkit-box",
    flexGrow: "1",
    marginBottom: "auto",
    maxHeight: "40px",
    overflow: "hidden",
  } as const,
};
