export default {
  empty_state: {
    subtitle: "Não temos informações disponíveis.",
    title: "Não há dados",
  },
  home: {
    welcome: "Bom ver você aqui novamente.",
  },
  loading: {
    title: "Carregando...",
  },
  login: {
    key: "E-mail ou Telefone",
    password: "Senha",
    submit: "Entrar",
    subtitle: "Acesse sua conta aqui:",
    welcome: "Olá! É muito bom ver você aqui novamente.",
  },
  notifications: {
    see_all: "Abrir Notificações",
  },
  pagination: {
    next: "Próximo",
    preposition: "de",
    prev: "Anterior",
  },
  purchases: {
    filter: {
      campus: "Campus",
      course: "Curso",
      entry: "Filtrar",
      submit: "Filtrar",
      type: "Tipo",
    },
    table: {
      buyer: "Comprador",
      course: "Curso",
      date: "Data",
      id: "Id",
      seller: "Vendedor",
      status: "Status",
    },
    table_actions: {
      details: "Detalhes da Venda",
      installments: "Ver Parcelas",
    },
    title: "Histórico de Vendas",
  },
  shopping: {
    filter: {
      name: "Nome do Produto",
      sort: {
        options: {
          maxPrice: "Maior preço",
          maxStorage: "Maior estoque",
          minPrice: "Menor preço",
          new: "Novidade",
        },
        title: "Ordem de Exibição",
      },
    },
    item: {
      button: "Ver Detalhes",
      points: "{{points}} pontos",
      stock: "{{stock}} unidades disponíveis",
    },
    title: "Shopping de Pontos",
  },
  shopping_item_detail: {
    button: "Adquirir",
    confirm_modal_button: "Confirmar",
    confirm_modal_description:
      "Você está trocando {{points}} pontos por {{quantity}} unidade(s) de {{item}}.",
    confirm_modal_title: "CONFIRME SUA COMPRA",
    confirm_success_msg:
      "Seu pedido foi confirmado, Enviaremos uma notificação no seu e-mail!",
    observations: "Observações",
    observations_placeholder: "Digite alguma observação caso se aplique...",
    points: "{{points}} pontos",
    quantity: "Quantidade",
    quantity_placeholder: "Selecione...",
    title: "Shopping de Pontos",
    total_points: "Pontos totais: {{points}}",
  },
  sidebar: {
    logout: "Sair",
    navigation: {
      challenges: "Desafios",
      commissions: "Comissões",
      courses: "Cursos",
      home: "Home",
      journey_section: "Jornada do Saber",
      points: "Pontos",
      profile: "Meu Perfil",
      purchases: "Histórico de Vendas",
      shopping: "Shopping",
      training: "Treinamento",
    },
    welcome: "Olá, {{name}}",
    widget: {
      commission_subtitle: "em comissões",
      ranking_subtitle: "no ranking",
    },
  },
  theme: {
    colors: {
      buttonPrimaryFill: "#149AFF",
    },
    custom: {
      backgroundColor: "#f0f0f0",
      footerColor: "#1B1B1B",
      foregroundColor: "#FFF",
      headerColor: "#FFF",
      navigationIconColor: "#149AFF",
      navigationSectionTitleColor: "#004579",
      navigationSelectedIndicatorColor: "#149AFF",
    },
  },
  training: {
    banner: {
      paragraph:
        "São vídeos e materiais explicativos que ajudarão no seu melhor desempenho. Aproveite!",
      title:
        "Preparamos aqui o conteúdo necessário para você começar essa etapa.",
    },
    docs: {
      action: "Download",
      paragraph: "Saiba mais sobre o programa",
    },
    headings: {
      docs: "Documentos",
      images: "Imagens",
      videos: "Vídeos",
    },
    image: {
      action: "Download",
    },
    video: {
      action: "Assistir",
      heading: "#{{index}} Vídeo",
      paragraph: "Cuidadosamente preparado para você",
    },
  },
};
