import { useStyletron } from "baseui";
import { Button, KIND } from "baseui/button";
import { ANCHOR, Drawer, SIZE } from "baseui/drawer";
import { Menu } from "baseui/icon";
import { StatefulMenu } from "baseui/menu";
import { PLACEMENT, StatefulPopover, TRIGGER_TYPE } from "baseui/popover";
import * as React from "react";

import {
  CampaignAllowListT,
  useCampaignAllowList,
} from "../../hooks/use-campaign-allow-list";
import { useScreenSize } from "../../hooks/use-screen-size";
import {
  DRAWER_TABLE_ACTIONS_OVERRIDE,
  POPOVER_TABLE_ACTIONS_OVERRIDE,
  STATE_MENU_TABLE_ACTIONS_OVERRIDE,
} from "../common-overrides";

export type TableActionItem = CampaignAllowListT & {
  label: string;
};

const TableActions = ({
  items,
  onItemSelected,
}: {
  items: Array<TableActionItem>;
  onItemSelected: (item: TableActionItem) => void;
}): React.ReactNode => {
  const [, theme] = useStyletron();
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
  const screenSize = useScreenSize();
  const campaignAllowListFilter = useCampaignAllowList();

  const content = ({ close }: { close: () => void }) => (
    <React.Fragment>
      <StatefulMenu
        items={items.filter(campaignAllowListFilter)}
        onItemSelect={(a) => {
          close();
          onItemSelected(a.item);
        }}
        overrides={STATE_MENU_TABLE_ACTIONS_OVERRIDE}
      />
    </React.Fragment>
  );

  if (screenSize.width > theme.breakpoints.medium) {
    return (
      <StatefulPopover
        autoFocus
        content={content}
        overrides={POPOVER_TABLE_ACTIONS_OVERRIDE}
        placement={PLACEMENT.right}
        returnFocus
        showArrow
        triggerType={TRIGGER_TYPE.click}
      >
        <Button kind={KIND.secondary} size="mini">
          <Menu />
        </Button>
      </StatefulPopover>
    );
  }

  return (
    <React.Fragment>
      <Drawer
        anchor={ANCHOR.bottom}
        autoFocus
        isOpen={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        overrides={DRAWER_TABLE_ACTIONS_OVERRIDE}
        size={SIZE.auto}
      >
        {content({ close: () => setIsDrawerOpen(false) })}
      </Drawer>
      <Button
        kind={KIND.secondary}
        onClick={() => setIsDrawerOpen(true)}
        size="mini"
      >
        <Menu />
      </Button>
    </React.Fragment>
  );
};
export default TableActions;
