import { Theme } from "baseui";

export const STYLES = {
  button: (theme: Theme) =>
    ({
      marginTop: theme.sizing.scale500,
      maxWidth: "100%",
      width: "fit-content",
    }) as const,
  container: (theme: Theme) =>
    ({
      ...theme.borders.border300,
      minWidth: "240px",
      overflow: "hidden",
      padding: theme.sizing.scale600,
      width: "calc(20% - 48px)",
    }) as const,
  content: (theme: Theme) =>
    ({
      display: "flex",
      flexDirection: "column",
      height: "100%",
      padding: theme.sizing.scale600,
    }) as const,
  heading: {
    margin: "0",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  } as const,
  img: {
    aspectRatio: "1",
    objectFit: "contain",
    width: "100%",
  } as const,
  paragraph: {
    "-webkit-box-orient": "vertical",
    "-webkit-line-clamp": "2",
    display: "-webkit-box",
    flexGrow: "1",
    margin: "0",
    maxHeight: "40px",
    overflow: "hidden",
  } as const,
  paragraphSecondary: (theme: Theme) =>
    ({
      margin: `${theme.sizing.scale100} 0 0 0`,
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    }) as const,
};
