import { useStyletron } from "baseui";
import * as React from "react";

import { Row } from "../containers";
import { STYLES } from "./styles";

const ConditionalSlider = ({
  children,
  className,
  condition,
  hideScrollbar,
}: {
  children?: React.ReactNode;
  className?: string;
  condition: boolean;
  hideScrollbar: boolean;
}): React.ReactNode => {
  const [css, theme] = useStyletron();
  const wrapperRef = React.useRef(null);
  const [isDown, setIsDown] = React.useState(false);
  const [startX, setStartX] = React.useState<number>(0);
  const [startY, setStartY] = React.useState<number>(0);
  const [scrollLeft, setScrollLeft] = React.useState<number>(0);
  const [scrollTop, setScrollTop] = React.useState<number>(0);

  const mousedown = React.useCallback(
    (e: React.SyntheticEvent) => {
      if (wrapperRef.current) {
        const event = e.nativeEvent as MouseEvent;
        const wrapper = wrapperRef.current as HTMLElement;
        setIsDown(true);
        setStartX(event.pageX - wrapper.offsetLeft);
        setStartY(event.pageY - wrapper.offsetTop);
        setScrollLeft(wrapper.scrollLeft);
        setScrollTop(wrapper.scrollTop);
        wrapper.style.cursor = "grabbing";
      }
    },
    [wrapperRef.current],
  );

  const mouseleave = React.useCallback(() => {
    if (wrapperRef.current) {
      const wrapper = wrapperRef.current as HTMLElement;
      setIsDown(false);
      wrapper.style.cursor = "grab";
    }
  }, [wrapperRef.current]);

  const mouseup = React.useCallback(() => {
    if (wrapperRef.current) {
      const wrapper = wrapperRef.current as HTMLElement;
      setIsDown(false);
      wrapper.style.cursor = "grab";
    }
  }, [wrapperRef.current]);

  const mousemove = React.useCallback(
    (e: React.SyntheticEvent) => {
      if (wrapperRef.current) {
        const event = e.nativeEvent as MouseEvent;
        const wrapper = wrapperRef.current as HTMLElement;
        if (!isDown) return;
        e.preventDefault();
        const x = event.pageX - wrapper.offsetLeft;
        const y = event.pageY - wrapper.offsetTop;
        const walkX = (x - startX) * 1; // Change this number to adjust the scroll speed
        const walkY = (y - startY) * 1; // Change this number to adjust the scroll speed
        wrapper.scrollLeft = scrollLeft - walkX;
        wrapper.scrollTop = scrollTop - walkY;
      }
    },
    [wrapperRef.current, isDown, startX, startY, scrollLeft, scrollTop],
  );

  if (condition) {
    return (
      <Row
        className={[
          ...(hideScrollbar
            ? css(STYLES.noScrollbarWrapper(theme, STYLES.wrapper(theme)))
            : css(STYLES.wrapper(theme))
          ).split(" "),
          className || "",
        ].join(" ")}
        onMouseDown={mousedown}
        onMouseLeave={mouseleave}
        onMouseMove={mousemove}
        onMouseUp={mouseup}
        ref={wrapperRef}
      >
        {children}
      </Row>
    );
  }
  return children;
};

export default ConditionalSlider;
