import { useStyletron } from "baseui";
import { LabelLarge, LabelMedium } from "baseui/typography";
import * as React from "react";

import { CustomTheme } from "../../../app/theme";
import {
  CampaignAllowListT,
  useCampaignAllowList,
} from "../../../hooks/use-campaign-allow-list";
import { Column, Row } from "../../containers";
import If from "../../if";
import { STYLES } from "./styles";

export type NavigationSection = CampaignAllowListT & {
  items: Array<NavigationItem>;
  title?: string;
};

export type NavigationItem = CampaignAllowListT & {
  icon?: React.ReactNode;
  itemId: string;
  title: string;
};

export const Navigation = ({
  activeItemId,
  onChange,
  sections,
}: {
  activeItemId: string;
  onChange: ({ item }: { item: NavigationItem }) => void;
  sections: Array<NavigationSection>;
}): React.ReactNode => {
  const [css, theme] = useStyletron();
  const campaignAllowListFilter = useCampaignAllowList();
  const { navigationSectionTitleColor } = (theme as CustomTheme).custom;

  return (
    <Column className={css(STYLES.wrapper(theme))}>
      {sections
        .filter(campaignAllowListFilter)
        .map((section, sectionIndex: number) => (
          <React.Fragment key={`section:${sectionIndex}`}>
            <If condition={section.title !== undefined}>
              <Row className={css(STYLES.navigationSectionWrapper(theme))}>
                <LabelLarge color={navigationSectionTitleColor}>
                  {section.title}
                </LabelLarge>
              </Row>
            </If>
            {section.items
              .filter(campaignAllowListFilter)
              .map((item: NavigationItem, itemIndex: number) => (
                <Row
                  className={css(STYLES.navigationItemWrapper(theme))}
                  key={`section:${sectionIndex}:item:${itemIndex}`}
                  onClick={() => onChange({ item })}
                >
                  <Column
                    className={css(
                      STYLES.navigationItemSelectedIndicator(
                        theme,
                        item.itemId === activeItemId,
                      ),
                    )}
                  />
                  <Column className={css(STYLES.navigationIconWrapper(theme))}>
                    {item.icon}
                  </Column>
                  <LabelMedium color={"#4B4B4B"}>{item.title}</LabelMedium>
                </Row>
              ))}
          </React.Fragment>
        ))}
      <Row className={css(STYLES.navigationSeparator(theme))} />
    </Column>
  );
};
