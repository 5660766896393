import axios from "axios";

import { BaseURL } from "..";

export const getNotifications = () => {
  return axios.post(`${BaseURL}/notification`, {});
};

export const markNotificationAsRead = (notificationUserId: number) => {
  return axios.patch(`${BaseURL}/notification/${notificationUserId}`, {});
};
