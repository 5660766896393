import { useStyletron } from "baseui";
import { HeadingSmall } from "baseui/typography";
import * as React from "react";
import { useTranslation } from "react-i18next";

import { API } from "../../api";
import { ShoppingItemRequestT, ShoppingItemT } from "../../api/shopping/types";
import { Row } from "../../components/containers";
import EmptyState from "../../components/empty-state";
import If from "../../components/if";
import Loading from "../../components/loading";
import MasterPage from "../../components/master-page";
import useFilter, { FilterFormDefaultT } from "../../hooks/use-filter";
import ShoppingItemCard from "./shopping-item-card";
import { STYLES } from "./styles";

const Shopping = (): React.ReactNode => {
  const [css, theme] = useStyletron();
  const { t: translate } = useTranslation();
  const [isLoading, setIsLoading] = React.useState(false);
  const [itemsData, setItemsData] = React.useState<
    Array<ShoppingItemT> | undefined
  >(undefined);

  const isSectionEmpty = !isLoading && (itemsData?.length || 0) === 0;

  const defaultForm: Array<FilterFormDefaultT> = [
    {
      defaultValue: "",
      isTagCloseable: true,
      key: "name",
      label: translate("shopping.filter.name"),
      type: "INPUT",
    },
    {
      defaultValue: undefined,
      isTagCloseable: true,
      key: "sort",
      label: translate("shopping.filter.sort.title"),
      options: [
        {
          id: "min-price",
          label: translate("shopping.filter.sort.options.minPrice"),
        },
        {
          id: "max-price",
          label: translate("shopping.filter.sort.options.maxPrice"),
        },
        { id: "new", label: translate("shopping.filter.sort.options.new") },
        {
          id: "max-storage",
          label: translate("shopping.filter.sort.options.maxStorage"),
        },
      ],
      type: "SELECT",
    },
  ];

  const fetchShoppingItemsData = React.useCallback(
    async (body: ShoppingItemRequestT) => {
      setIsLoading(true);
      const { items } = await API.getShoppingItems(body);
      setItemsData(sortItems(items, body.sort));
      setIsLoading(false);
    },
    [],
  );

  React.useEffect(() => {
    fetchShoppingItemsData({ active: 1 });
  }, []);

  const [Filter, FilterTags] = useFilter({
    defaultForm,
    onFilter: (form: object) => {
      fetchShoppingItemsData({ ...form, active: 1 });
    },
    txtEntryPoint: translate("purchases.filter.entry"),
    txtSubmit: translate("purchases.filter.submit"),
  });

  const sortItems = React.useCallback(
    (items: Array<ShoppingItemT>, sortKey?: string): Array<ShoppingItemT> => {
      switch (sortKey) {
        case "min-price":
          return items.sort((a, b) => {
            if (a.promotionalPoints && b.promotionalPoints) {
              return a.promotionalPoints - b.promotionalPoints;
            }

            if (a.promotionalPoints && b.points) {
              return a.promotionalPoints - b.points;
            }

            if (a.points && b.promotionalPoints) {
              return a.points - b.promotionalPoints;
            }
            return a.points - b.points;
          });
        case "max-price":
          return items.sort((a, b) => {
            if (b.promotionalPoints && a.promotionalPoints) {
              return b.promotionalPoints - a.promotionalPoints;
            }

            if (b.promotionalPoints && a.points) {
              return b.promotionalPoints - a.points;
            }

            if (b.points && a.promotionalPoints) {
              return b.points - a.promotionalPoints;
            }

            return b.points - a.points;
          });
        case "max-storage":
          return items.sort((a, b) => b.stock - a.stock);
        case "new":
          return items.sort(
            (a, b) => +new Date(b.created_at) - +new Date(a.created_at),
          );
        default:
          return items;
      }
    },
    [],
  );

  return (
    <MasterPage hasSidebar={true}>
      <React.Fragment>
        <Row className={css(STYLES.headingRow(theme))}>
          <HeadingSmall margin={0} style={{ fontWeight: "500" }}>
            {translate("shopping.title")}
          </HeadingSmall>
        </Row>
        <Row className={css(STYLES.headingRow(theme))}>{Filter}</Row>
        <If condition={Boolean(FilterTags)}>
          <Row className={css(STYLES.headingRow(theme))}>{FilterTags}</Row>
        </If>
      </React.Fragment>
      <Loading isLoading={isLoading}>
        <EmptyState isEmpty={isSectionEmpty}>
          <Row className={css(STYLES.itemsContainer(theme))}>
            {itemsData?.map((item) => (
              <ShoppingItemCard item={item} key={item.id} />
            ))}
          </Row>
        </EmptyState>
      </Loading>
    </MasterPage>
  );
};
export default Shopping;
