import * as NotificationApi from "./notification";
import * as PurchaseApi from "./purchase";
import * as RankingApi from "./ranking";
import * as ShoppingApi from "./shopping";
import * as TrainingApi from "./training";
import * as LoginApi from "./user";

export const BaseURL = "https://api-sandbox.saberemrede.net";

export type ValidationError = {
  description: string;
  expectedError: boolean;
  name: string;
};

export const API = {
  ...LoginApi,
  ...PurchaseApi,
  ...RankingApi,
  ...ShoppingApi,
  ...TrainingApi,
  ...NotificationApi,
};
