import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { ParagraphMedium, ParagraphSmall } from "baseui/typography";
import * as React from "react";

import { Column, Row } from "../containers";
import logoCuboItau from "./assets/cubo-itau.png";
import envelope from "./assets/envelope.svg";
import facebook from "./assets/facebook.svg";
import instagram from "./assets/instagram.svg";
import linkedin from "./assets/linkedin.svg";
import logo from "./assets/ser-logo.png";
import logoStartupSP from "./assets/startup-sp-sebrae.png";
import whatsapp from "./assets/whatsapp.svg";
import youtube from "./assets/youtube.svg";
import { STYLES } from "./styles";

const Footer = (): React.ReactNode => {
  const [css, theme] = useStyletron();

  return (
    <Row className={css(STYLES.container(theme))}>
      <Row className={css(STYLES.innerContainer(theme))}>
        <Column className={css(STYLES.itemColumn(theme))}>
          <img alt="logo" src={logo} width={160} />
        </Column>
        <Column className={css(STYLES.itemColumn(theme))}>
          <Row className={css(STYLES.socialMediaContainer(theme))}>
            <img
              alt="whatsapp"
              src={whatsapp}
              width={theme.typography.ParagraphLarge.fontSize}
            />
            <img
              alt="instagram"
              src={instagram}
              width={theme.typography.ParagraphLarge.fontSize}
            />
            <img
              alt="facebook"
              src={facebook}
              width={theme.typography.ParagraphLarge.fontSize}
            />
            <img
              alt="linkedin"
              src={linkedin}
              width={theme.typography.ParagraphLarge.fontSize}
            />
            <img
              alt="youtube"
              src={youtube}
              width={theme.typography.ParagraphLarge.fontSize}
            />
          </Row>
          <Row className={css(STYLES.addressRow(theme))}>
            <ParagraphMedium
              className={css(STYLES.paragraph(theme))}
              color={theme.colors.contentInversePrimary}
            >
              {"Al. Vicente Pinzon, 54"} <br />
              {" Vila Olímpia, São Paulo - SP"} <br />
              {"04547-130 - 8º andar"}
            </ParagraphMedium>
          </Row>
          <Row className={css(STYLES.addressRow(theme))}>
            <ParagraphMedium
              className={css(STYLES.paragraph(theme))}
              color={theme.colors.contentInversePrimary}
            >
              <img
                alt="contact"
                src={envelope}
                style={{ marginRight: theme.sizing.scale300 }}
                width={theme.typography.ParagraphMedium.fontSize}
              />
              {"contato@saberemrede.net.br"}
            </ParagraphMedium>
          </Row>
          <Block className={css(STYLES.politicsLinksContainer(theme))}>
            <ParagraphMedium
              className={css({
                ...STYLES.paragraph(theme),
                ...STYLES.hideSmall(theme),
              })}
              color={theme.colors.contentInversePrimary}
            >
              {"Política de Privacidade | Política de Cookies | Fale com o DPO"}
            </ParagraphMedium>
            <ParagraphMedium
              className={css({
                ...STYLES.paragraph(theme),
                ...STYLES.hideLarge(theme),
              })}
              color={theme.colors.contentInversePrimary}
            >
              {"Política de Privacidade"}
            </ParagraphMedium>
            <ParagraphMedium
              className={css({
                ...STYLES.paragraph(theme),
                ...STYLES.hideLarge(theme),
              })}
              color={theme.colors.contentInversePrimary}
            >
              {"Política de Cookies"}
            </ParagraphMedium>
            <ParagraphMedium
              className={css({
                ...STYLES.paragraph(theme),
                ...STYLES.hideLarge(theme),
              })}
              color={theme.colors.contentInversePrimary}
            >
              {"Fale com o DPO"}
            </ParagraphMedium>
          </Block>
        </Column>
        <Column>
          <Row>
            <ParagraphSmall
              color={theme.colors.contentInversePrimary}
              marginBottom={theme.sizing.scale0}
              marginTop={theme.sizing.scale300}
            >
              {"Empresa participante do"}
            </ParagraphSmall>
          </Row>
          <Row className={css(STYLES.partnersLogoContainer(theme))}>
            <img alt="partner" height={47} src={logoStartupSP} width={160} />
            <img alt="partner" height={85} src={logoCuboItau} width={85} />
          </Row>
        </Column>
      </Row>
    </Row>
  );
};

export default Footer;
