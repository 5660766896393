import br from "./br";
import brIndicaPravaler from "./br-indica-pravaler";
import brUninter from "./br-uninter";

// must use snake_case `br_${campaign}`
export const locales = {
  br: {
    translation: br,
  },
  br_indicapravaler: {
    translation: brIndicaPravaler,
  },
  br_uninter: {
    translation: brUninter,
  },
};
