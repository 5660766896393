import {
  DisplaySmall,
  HeadingSmall,
  LabelLarge,
  MonoDisplaySmall,
  MonoHeadingSmall,
  MonoLabelLarge,
  MonoParagraphLarge,
  ParagraphLarge,
} from "baseui/typography";
import * as React from "react";
import { useTranslation } from "react-i18next";

import MasterPage from "../../components/master-page";

const Home = (): React.ReactNode => {
  const { t: translate } = useTranslation();

  return (
    <MasterPage hasSidebar={true}>
      <DisplaySmall margin={0}>{translate("home.welcome")}</DisplaySmall>
      <HeadingSmall margin={0}>{translate("home.welcome")}</HeadingSmall>
      <ParagraphLarge margin={0}>{translate("home.welcome")}</ParagraphLarge>
      <LabelLarge margin={0}>{translate("home.welcome")}</LabelLarge>
      <MonoDisplaySmall margin={0}>
        {translate("home.welcome")}
      </MonoDisplaySmall>
      <MonoHeadingSmall margin={0}>
        {translate("home.welcome")}
      </MonoHeadingSmall>
      <MonoParagraphLarge margin={0}>
        {translate("home.welcome")}
      </MonoParagraphLarge>
      <MonoLabelLarge margin={0}>{translate("home.welcome")}</MonoLabelLarge>
    </MasterPage>
  );
};
export default Home;
