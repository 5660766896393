import { useStyletron } from "baseui";
import { Accordion, Panel } from "baseui/accordion";
import { LabelMedium, ParagraphSmall } from "baseui/typography";
import * as React from "react";
import { useParams } from "react-router-dom";

import { API } from "../../api";
import { Column, Row } from "../../components/containers";
import EmptyState from "../../components/empty-state";
import { NotificationDotIcon } from "../../components/icons";
import If from "../../components/if";
import Loading from "../../components/loading";
import MasterPage from "../../components/master-page";
import { useScreenSize } from "../../hooks/use-screen-size";
import { ApiContext } from "../../providers/api-provider";
import { STYLES } from "./styles";

export const Notifications = (): React.ReactNode => {
  const [css, theme] = useStyletron();
  const [expandedPanelKeys, setExpandedPanelKeys] = React.useState<React.Key[]>(
    [],
  );
  const [loadedIFrames, setLoadedIFrames] = React.useState<Array<string>>([]);
  const routeParams = useParams();
  const screenSize = useScreenSize();
  const apiContext = React.useContext(ApiContext);

  const notifications = React.useMemo(() => {
    if (routeParams && routeParams.id && apiContext.notifications) {
      return apiContext.notifications.filter(
        (n) => n.id.toString() === routeParams?.id?.toString(),
      );
    }
    return apiContext.notifications;
  }, [routeParams.id, apiContext.notifications]);

  const getIframeHeight = React.useCallback(
    (id: number) => {
      const element = document.getElementById(
        `ntf-iframe-${id}`,
      ) as HTMLIFrameElement;
      if (!element) {
        return 0;
      }
      const { scrollHeight } = element.contentWindow?.document?.body || {};
      if (scrollHeight) {
        return scrollHeight + 1;
      }
      return 0;
    },
    [
      expandedPanelKeys,
      apiContext.notifications,
      screenSize.width,
      loadedIFrames,
    ],
  );

  const getPanelTitle = React.useCallback(
    (index: number): React.ReactNode => {
      if (!notifications) {
        return <></>;
      }
      const notification = notifications[index];
      return (
        <Row className={css(STYLES.contentRow(theme))}>
          <If condition={!notification.NotificationUsers[0]?.readDate}>
            <NotificationDotIcon color={theme.colors.buttonPrimaryFill} />
          </If>
          <Column className={css(STYLES.contentColumn)}>
            <LabelMedium>{notification.title}</LabelMedium>
            <ParagraphSmall className={css(STYLES.contentParagraph(theme))}>
              {notification.resume}
            </ParagraphSmall>
          </Column>
        </Row>
      );
    },
    [notifications],
  );

  const onAccordionExpanded = React.useCallback(
    (a: { expanded: Array<React.Key> }) => {
      if (!notifications) {
        return;
      }
      setExpandedPanelKeys(a.expanded);
      for (const key of a.expanded) {
        const notification = notifications.find(
          (n) => n.id.toString() === key.toString(),
        );
        if (!notification) {
          break;
        }
        const notificationUser = notification.NotificationUsers[0];
        if (!notificationUser) {
          break;
        }
        if (!notificationUser.readDate) {
          API.markNotificationAsRead(notificationUser.id);
          notification.NotificationUsers[0].readDate = new Date().toISOString();
        }
      }
    },
    [notifications],
  );

  return (
    <MasterPage hasSidebar={true}>
      <Loading isLoading={!notifications}>
        <EmptyState
          isEmpty={Boolean(notifications && notifications.length <= 0)}
        >
          <Accordion accordion onChange={onAccordionExpanded} renderAll>
            {notifications &&
              notifications.map((notification, index) => {
                const iframeId = `ntf-iframe-${notification.id}`;
                return (
                  <Panel
                    expanded={routeParams.id ? true : undefined}
                    key={notification.id}
                    title={getPanelTitle(index)}
                  >
                    <iframe
                      className={css(
                        STYLES.iframe(getIframeHeight(notification.id)),
                      )}
                      id={iframeId}
                      onLoad={() =>
                        setLoadedIFrames((loaded) => {
                          return [...loaded, iframeId];
                        })
                      }
                      srcDoc={notification.template}
                    />
                  </Panel>
                );
              })}
          </Accordion>
        </EmptyState>
      </Loading>
    </MasterPage>
  );
};
