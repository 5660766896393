import { useStyletron } from "baseui";
import { Button } from "baseui/button";
import { HeadingXSmall, ParagraphSmall } from "baseui/typography";
import * as React from "react";
import { useTranslation } from "react-i18next";

import { TrainingPageVideoT } from "../../../api/training/types";
import { Column } from "../../../components/containers";
import { STYLES } from "./styles";

const VideoCard = ({
  index,
  video,
}: {
  index?: number;
  video: TrainingPageVideoT;
}): React.ReactNode => {
  const [css, theme] = useStyletron();
  const { t: translate } = useTranslation();

  const ytVideoId = new URL(video.link).searchParams.get("v");
  return (
    <Column className={css(STYLES.container(theme))}>
      <img
        className={css(STYLES.img(theme))}
        src={`https://i.ytimg.com/vi/${ytVideoId}/hq720.jpg`}
      />
      <Column className={css(STYLES.content(theme))}>
        <HeadingXSmall className={css(STYLES.heading)}>
          {video.title ||
            translate("training.video.heading", {
              index: (index || 0) + 1,
            })}
        </HeadingXSmall>
        <ParagraphSmall className={css(STYLES.paragraph)}>
          {video.description || translate("training.video.paragraph")}
        </ParagraphSmall>
        <Button
          className={css(STYLES.button(theme))}
          kind="secondary"
          onClick={() => {
            window.open(video.link);
          }}
          size="compact"
        >
          {translate("training.video.action")}
        </Button>
      </Column>
    </Column>
  );
};

export default VideoCard;
