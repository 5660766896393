import { Theme } from "baseui";

export const STYLES = {
  imageCardContainer: (theme: Theme, actionStr: string) =>
    ({
      [":hover:after"]: {
        ...theme.typography.LabelSmall,
        background: theme.colors.buttonPrimaryFill,
        borderRadius: theme.sizing.scale300,
        color: theme.colors.buttonPrimaryText,
        content: `'${actionStr}'`,
        left: "50%",
        padding: `${theme.sizing.scale400} ${theme.sizing.scale500}`,
        position: "absolute",
        top: "50%",
        transform: "translate(-50%, -50%)",
      },
      [":hover:before"]: {
        background: "rgb(51, 51, 51, 0.4)",
        bottom: 0,
        content: "''",
        left: 0,
        position: "absolute",
        right: 0,
        top: 0,
      },
      borderRadius: theme.sizing.scale600,
      cursor: "pointer",
      minWidth: "fit-content",
      overflow: "hidden",
      position: "relative",
    }) as const,
  imageCardImg: {
    height: "300px",
    objectFit: "cover",
    width: "auto",
  } as const,
};
