import { Theme } from "baseui";

export const STYLES = {
  docsListContainer: {
    margin: 0,
    padding: 0,
    width: "100%",
  } as const,
  itemsContainer: (theme: Theme) =>
    ({
      flexWrap: "wrap",
      gap: theme.sizing.scale600,
      justifyContent: "center",
      minWidth: "100%",
      width: "100%",
    }) as const,
};
