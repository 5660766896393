import { Theme } from "baseui";

import { CustomTheme } from "../../app/theme";

export const STYLES = {
  addressRow: (theme: Theme) => ({
    [`@media screen and (max-width: ${theme.breakpoints.large}px)`]: {
      justifyContent: "center",
    },
  }),
  container: (theme: Theme) => ({
    backgroundColor: (theme as CustomTheme).custom.footerColor,
    margin: "0 auto",
    maxWidth: "1920px",
    minHeight: "240px",
  }),
  hideLarge: (theme: Theme) => ({
    display: "none",
    [`@media screen and (max-width: ${theme.breakpoints.large}px)`]: {
      display: "block",
    },
  }),
  hideSmall: (theme: Theme) => ({
    display: "block",
    [`@media screen and (max-width: ${theme.breakpoints.large}px)`]: {
      display: "none",
    },
  }),
  innerContainer: (theme: Theme) =>
    ({
      alignItems: "center",
      display: "flex",
      flexWrap: "wrap",
      gap: `${theme.sizing.scale900} ${theme.sizing.scale1200}`,
      justifyContent: "center",
      margin: "0 auto",
      maxWidth: "1366px",
      padding: `${theme.sizing.scale600}`,
    }) as const,
  itemColumn: (theme: Theme) =>
    ({
      [`@media screen and (max-width: ${theme.breakpoints.large}px)`]: {
        alignItems: "center",
        width: "100%",
      },
    }) as const,
  paragraph: (theme: Theme) =>
    ({
      marginBottom: theme.sizing.scale0,
      marginTop: theme.sizing.scale300,
      [`@media screen and (max-width: ${theme.breakpoints.large}px)`]: {
        textAlign: "center",
        width: "100%",
      },
    }) as const,
  partnersLogoContainer: (theme: Theme) => ({
    alignItems: "center",
    gap: theme.sizing.scale300,
  }),
  politicsLinksContainer: (theme: Theme) =>
    ({
      display: "flex",
      flexDirection: "row",
      [`@media screen and (max-width: ${theme.breakpoints.large}px)`]: {
        alignItems: "center",
        flexDirection: "column",
      },
    }) as const,
  socialMediaContainer: (theme: Theme) => ({
    color: theme.colors.contentInversePrimary,
    fontSize: theme.typography.ParagraphLarge.fontSize,
    gap: theme.sizing.scale300,
    [`@media screen and (max-width: ${theme.breakpoints.large}px)`]: {
      justifyContent: "center",
    },
  }),
};
