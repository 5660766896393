import { useStyletron } from "baseui";
import { Button, KIND, SIZE } from "baseui/button";
import { ListItem, ListItemLabel } from "baseui/list";
import * as React from "react";
import { useTranslation } from "react-i18next";

import { TrainingPageDocT } from "../../../api/training/types";

const DocCard = ({ doc }: { doc: TrainingPageDocT }): React.ReactNode => {
  const [, theme] = useStyletron();
  const { t: translate } = useTranslation();

  const actionButton = React.useCallback(
    () => (
      <Button
        kind={KIND.primary}
        onClick={() => {
          window.open(doc.link);
        }}
        size={SIZE.compact}
        style={{ marginLeft: theme.sizing.scale300 }}
      >
        {translate("training.docs.action")}
      </Button>
    ),
    [],
  );

  return (
    <ListItem endEnhancer={actionButton} key={doc.id}>
      <ListItemLabel>
        {doc.description || translate("training.docs.paragraph")}
      </ListItemLabel>
    </ListItem>
  );
};

export default DocCard;
