import { Theme } from "baseui";

export const STYLES = {
  container: {
    alignItems: "center",
    width: "100%",
  },
  filterContainer: (theme: Theme) => ({
    flex: "0",
    gap: theme.sizing.scale600,
    width: "100%",
  }),
  headerContainer: (theme: Theme) => ({
    gap: theme.sizing.scale600,
    marginBottom: theme.sizing.scale600,
    width: "100%",
  }),
  table: {
    width: "100%",
  },
  tableActionColumn: {
    alignItems: "center",
    display: "flex",
  },
  tableContainer: (theme: Theme) => ({
    marginBottom: theme.sizing.scale600,
    width: "100%",
  }),
  tableRow: {
    display: "contents",
  },
};
