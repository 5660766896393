import { Theme } from "baseui";

export const STYLES = {
  avatarWelcomeText: {
    flex: "1",
    fontWeight: "500 !important",
    margin: "0",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textTransform: "capitalize",
    whiteSpace: "nowrap",
  } as const,
  avatarWrapper: (theme: Theme) => ({
    alignItems: "center",
    gap: theme.sizing.scale300,
    justifyContent: "space-between",
    padding: `${theme.sizing.scale600} ${theme.sizing.scale600} ${theme.sizing.scale300} ${theme.sizing.scale600}`,
  }),
  extraButtonsWrapper: (theme: Theme) =>
    ({
      padding: theme.sizing.scale600,
    }) as const,
};
