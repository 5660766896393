import axios from "axios";

import { BaseURL } from "..";

export const ranks = () => {
  return axios.post(`${BaseURL}/rank/ranks`, { filter: {} });
};

export const rankingCoins = () => {
  return axios.post(`${BaseURL}/ranking/ranking-coins`);
};
