import { useStyletron } from "baseui";
import * as React from "react";
import { useTranslation } from "react-i18next";

import { TrainingPageImageT } from "../../../api/training/types";
import { settings } from "../../../app/settings";
import { Column } from "../../../components/containers";
import { STYLES } from "./styles";

const ImageCard = ({
  image,
}: {
  image: TrainingPageImageT;
}): React.ReactNode => {
  const [css, theme] = useStyletron();
  const { t: translate } = useTranslation();

  return (
    <Column
      className={css(
        STYLES.imageCardContainer(theme, translate("training.image.action")),
      )}
      key={image.id}
      onClick={() => window.open(`${settings.aws.s3.BASE_URL}/${image.link}`)}
    >
      <img
        className={css(STYLES.imageCardImg)}
        src={`${settings.aws.s3.BASE_URL}/${image.link}`}
      />
    </Column>
  );
};

export default ImageCard;
