import { useStyletron } from "baseui";
import { Pagination } from "baseui/pagination";
import { StyledBodyCell, StyledHeadCell, StyledTable } from "baseui/table-grid";
import { HeadingSmall } from "baseui/typography";
import { format } from "date-fns";
import * as React from "react";
import { useTranslation } from "react-i18next";

import { API } from "../../api";
import { SoldPurchasesRequestT } from "../../api/purchase/types";
import { Column, Row } from "../../components/containers";
import EmptyState from "../../components/empty-state";
import Loading from "../../components/loading";
import MasterPage from "../../components/master-page";
import TableActions, { TableActionItem } from "../../components/table-actions";
import useFilter, { FilterFormDefaultT } from "../../hooks/use-filter";
import { STYLES } from "./styles";

const Purchases = (): React.ReactNode => {
  const [css, theme] = useStyletron();
  const { t: translate } = useTranslation();
  const [isLoading, setIsLoading] = React.useState(false);
  const [tableData, setTableData] = React.useState<Array<Array<string>>>([]);
  const [tableDataPaginated, setTableDataPaginated] = React.useState<
    Array<Array<string>>
  >([]);
  const [currentPage, setCurrentPage] = React.useState(1);

  const actionItems: Array<TableActionItem> = [
    {
      label: translate("purchases.table_actions.details"),
    },
    {
      label: translate("purchases.table_actions.installments"),
    },
  ];

  const defaultForm: Array<FilterFormDefaultT> = [
    {
      defaultValue: "",
      isTagCloseable: true,
      key: "name",
      label: translate("purchases.filter.course"),
      type: "INPUT",
    },
    {
      defaultValue: "",
      isTagCloseable: true,
      key: "campus",
      label: translate("purchases.filter.campus"),
      type: "INPUT",
    },
    {
      defaultValue: undefined,
      isTagCloseable: true,
      key: "tipo",
      label: translate("purchases.filter.type"),
      options: [
        { id: "P", label: "Presencial" },
        { id: "D", label: "A distância" },
        { id: "S", label: "Semi-presencial" },
      ],
      type: "SELECT",
    },
  ];

  const applySearch = React.useCallback(async (body: SoldPurchasesRequestT) => {
    setIsLoading(true);
    const { purchases } = await API.soldPurchases({ body });
    if (purchases && Array.isArray(purchases)) {
      setTableData(
        purchases.map((purchase) => [
          purchase.id,
          purchase.PurchaseItemStatus.name,
          purchase.created_at,
          purchase.name,
          purchase.Purchase.receiver.name,
          purchase.Purchase.buyer.name,
        ]),
      );
    }
    setTimeout(() => setIsLoading(false), 100);
  }, []);

  React.useEffect(() => {
    applySearch({ filters: {} });
  }, []);

  const [Filter, FilterTags] = useFilter({
    defaultForm,
    onFilter: (form: object) => {
      applySearch({ filters: form });
    },
    txtEntryPoint: translate("purchases.filter.entry"),
    txtSubmit: translate("purchases.filter.submit"),
  });

  React.useEffect(() => {
    const firstItem = (currentPage - 1) * 10;
    setTableDataPaginated(tableData.slice(firstItem, firstItem + 10));
  }, [currentPage, tableData]);

  return (
    <MasterPage hasSidebar={true}>
      <Column className={css(STYLES.container)}>
        <Column className={css(STYLES.headerContainer(theme))}>
          <HeadingSmall margin={0} style={{ fontWeight: "500" }}>
            {translate("purchases.title")}
          </HeadingSmall>
          <Row>{Filter}</Row>
          <Row>{FilterTags}</Row>
        </Column>
        <Loading isLoading={isLoading}>
          <EmptyState
            isEmpty={
              !isLoading &&
              (!tableDataPaginated || tableDataPaginated.length === 0)
            }
          >
            <Row className={css(STYLES.tableContainer(theme))}>
              <StyledTable
                $gridTemplateColumns={[
                  "64px",
                  "100px",
                  "120px",
                  "150px",
                  "minmax(400px, 1fr)",
                  "250px",
                  "250px",
                ].join(" ")}
                $style={STYLES.table}
                role="grid"
              >
                <div className={css(STYLES.tableRow)} role="row">
                  <StyledHeadCell />
                  <StyledHeadCell>
                    {translate("purchases.table.id")}
                  </StyledHeadCell>
                  <StyledHeadCell>
                    {translate("purchases.table.status")}
                  </StyledHeadCell>
                  <StyledHeadCell>
                    {translate("purchases.table.date")}
                  </StyledHeadCell>
                  <StyledHeadCell>
                    {translate("purchases.table.course")}
                  </StyledHeadCell>
                  <StyledHeadCell>
                    {translate("purchases.table.buyer")}
                  </StyledHeadCell>
                  <StyledHeadCell>
                    {translate("purchases.table.seller")}
                  </StyledHeadCell>
                </div>
                {tableDataPaginated.map((row, index: number) => {
                  const striped = index % 2 === 0;
                  return (
                    <div
                      className={css(STYLES.tableRow)}
                      key={index}
                      role="row"
                    >
                      <StyledBodyCell
                        $striped={striped}
                        className={css(STYLES.tableActionColumn)}
                      >
                        <TableActions
                          items={actionItems}
                          onItemSelected={console.log}
                        />
                      </StyledBodyCell>
                      <StyledBodyCell $striped={striped}>
                        {row[0]}
                      </StyledBodyCell>
                      <StyledBodyCell $striped={striped}>
                        {row[1]}
                      </StyledBodyCell>
                      <StyledBodyCell $striped={striped}>
                        {format(new Date(row[2]), "dd/MM/yyyy")}
                      </StyledBodyCell>
                      <StyledBodyCell $striped={striped}>
                        {row[3]}
                      </StyledBodyCell>
                      <StyledBodyCell $striped={striped}>
                        {row[4]}
                      </StyledBodyCell>
                      <StyledBodyCell $striped={striped}>
                        {row[5]}
                      </StyledBodyCell>
                    </div>
                  );
                })}
              </StyledTable>
            </Row>
            <Pagination
              currentPage={currentPage}
              labels={{
                nextButton: translate("pagination.next"),
                preposition: translate("pagination.preposition"),
                prevButton: translate("pagination.prev"),
              }}
              numPages={Math.ceil(tableData.length / 10)}
              onPageChange={({ nextPage }) => {
                setCurrentPage(Math.min(Math.max(nextPage, 1), 10));
              }}
            />
          </EmptyState>
        </Loading>
      </Column>
    </MasterPage>
  );
};
export default Purchases;
