import { Block } from "baseui/block";
import { PLACEMENT, ToasterContainer } from "baseui/toast";
import * as React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Client as Styletron } from "styletron-engine-atomic";
import { Provider as StyletronProvider } from "styletron-react";

import { ApiProvider } from "../providers/api-provider";
import { AuthProvider } from "../providers/auth-provider";
import AppRoutes from "./routes";
import ThemeProvider from "./theme";

// init styletron
const engine = new Styletron();

const Root = (): React.ReactNode => (
  <StyletronProvider debugAfterHydration value={engine}>
    <HelmetProvider>
      <Helmet>
        <title>Saber Em Rede</title>
        <meta
          content="width=device-width, initial-scale=1 initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
          name="viewport"
        />
        <style type="text/css">{"body{margin: 0px;}"}</style>
        <link href="https://fonts.googleapis.com" rel="preconnect" />
        <link
          crossOrigin="anonymous"
          href="https://fonts.gstatic.com"
          rel="preconnect"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Anonymous+Pro:ital,wght@0,400;0,700;1,400;1,700&family=Figtree:ital,wght@0,300..900;1,300..900&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <AuthProvider>
        <ApiProvider>
          <ThemeProvider>
            <AppRoutes />
            <ToasterContainer
              autoHideDuration={2500}
              placement={PLACEMENT.topRight}
            />
            <Block id="modal-mount-node" />
          </ThemeProvider>
        </ApiProvider>
      </AuthProvider>
    </HelmetProvider>
  </StyletronProvider>
);

export default Root;
