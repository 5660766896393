import { useStyletron } from "baseui";
import { Banner } from "baseui/banner";
import { FILL, Tab, Tabs } from "baseui/tabs-motion";
import { HeadingSmall } from "baseui/typography";
import * as React from "react";
import { useTranslation } from "react-i18next";

import { API } from "../../api";
import { TrainingPageT } from "../../api/training/types";
import { TABS_HIGHLIGHT_OVERRIDE } from "../../components/common-overrides";
import { Column, Row } from "../../components/containers";
import EmptyState from "../../components/empty-state";
import Loading from "../../components/loading";
import MasterPage from "../../components/master-page";
import { AuthContext } from "../../providers/auth-provider";
import DocCard from "./doc-card";
import ImageCard from "./image-card";
import { STYLES } from "./styles";
import VideoCard from "./video-card";

const Training = (): React.ReactNode => {
  const [activeKey, setActiveKey] = React.useState<React.Key>("0");
  const [css, theme] = useStyletron();
  const { t: translate } = useTranslation();
  const authContext = React.useContext(AuthContext);
  const [isLoading, setIsLoading] = React.useState(false);
  const [trainingData, setTrainingData] = React.useState<
    TrainingPageT | undefined
  >(undefined);

  const isVideoSectionEmpty =
    !isLoading && (trainingData?.TrainingPageVideos?.length || 0) === 0;
  const isDocSectionEmpty =
    !isLoading && (trainingData?.TrainingPageDocs?.length || 0) === 0;
  const isImageSectionEmpty =
    !isLoading && (trainingData?.TrainingPageImages?.length || 0) === 0;

  const fetchTrainingPageData = React.useCallback(async (id: number) => {
    setIsLoading(true);
    const { data } = await API.getTrainingPage(id);
    setTrainingData(data?.trainingPageSelected);
    setIsLoading(false);
  }, []);

  React.useEffect(() => {
    const { trainingPageId } = authContext.state;
    if (trainingPageId) {
      fetchTrainingPageData(trainingPageId);
    }
  }, [authContext.state.trainingPageId]);

  return (
    <MasterPage hasSidebar={true}>
      <Loading isLoading={isLoading}>
        <Column>
          <HeadingSmall margin={0} style={{ fontWeight: "500" }}>
            {trainingData?.name}
          </HeadingSmall>
          <Banner title={translate("training.banner.title")}>
            {translate("training.banner.paragraph")}
          </Banner>
        </Column>
        <Tabs
          activateOnFocus
          activeKey={activeKey}
          fill={FILL.fixed}
          onChange={({ activeKey }) => {
            setActiveKey(activeKey);
          }}
          overrides={TABS_HIGHLIGHT_OVERRIDE}
        >
          <Tab title={translate("training.headings.videos")}>
            <EmptyState isEmpty={isVideoSectionEmpty}>
              <Row className={css(STYLES.itemsContainer(theme))}>
                {trainingData?.TrainingPageVideos.map((video, index) => (
                  <VideoCard index={index} key={video.id} video={video} />
                ))}
              </Row>
            </EmptyState>
          </Tab>
          <Tab title={translate("training.headings.docs")}>
            <EmptyState isEmpty={isDocSectionEmpty}>
              <Row className={css(STYLES.itemsContainer(theme))}>
                <ul className={css(STYLES.docsListContainer)}>
                  {trainingData?.TrainingPageDocs.map((doc) => (
                    <DocCard doc={doc} key={doc.id} />
                  ))}
                </ul>
              </Row>
            </EmptyState>
          </Tab>
          <Tab title={translate("training.headings.images")}>
            <EmptyState isEmpty={isImageSectionEmpty}>
              <Row className={css(STYLES.itemsContainer(theme))}>
                {trainingData?.TrainingPageImages.map((image) => (
                  <ImageCard image={image} key={image.id} />
                ))}
              </Row>
            </EmptyState>
          </Tab>
        </Tabs>
      </Loading>
    </MasterPage>
  );
};
export default Training;
