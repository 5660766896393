import axios from "axios";

import { BaseURL } from "..";
import { SoldPurchasesRequestT } from "./types";

export const soldPurchases = async ({
  body,
}: {
  body?: SoldPurchasesRequestT;
}) => {
  return (await axios.post(`${BaseURL}/purchase/sold-purchases`, body)).data;
};

export const userCommission = () => {
  return axios.post(`${BaseURL}/purchase/user-commission`);
};
