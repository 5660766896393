import br from "./br";

export default {
  ...br,
  theme: {
    colors: {
      ...br.theme.colors,
      buttonPrimaryFill: "#E34C01",
    },
    custom: {
      ...br.theme.custom,
      backgroundColor: "#f0f0f0",
      footerColor: "#1B1B1B",
      foregroundColor: "#FFF",
      headerColor: "#FFF",
      navigationIconColor: "#FA5300",
      navigationSectionTitleColor: "#E34C01",
      navigationSelectedIndicatorColor: "#FA5300",
    },
  },
};
