import { useStyletron } from "baseui";
import { ANCHOR, Drawer } from "baseui/drawer";
import {
  ALIGN,
  HeaderNavigation,
  StyledNavigationItem,
  StyledNavigationList,
} from "baseui/header-navigation";
import { Menu } from "baseui/icon";
import * as React from "react";

import { useScreenSize } from "../../hooks/use-screen-size";
import { AuthContext } from "../../providers/auth-provider";
import If from "../if";
import { NotificationWidget } from "../notification-widget";
import Sidebar from "../sidebar";
import logo from "./assets/ser-colored-logo.png";
import { STYLES } from "./styles";

const Header = () => {
  const authContext = React.useContext(AuthContext);
  const [css, theme] = useStyletron();
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const screenSize = useScreenSize();

  const onOpenMenu = React.useCallback(() => {
    setIsMenuOpen(true);
  }, []);

  return (
    <React.Fragment>
      <HeaderNavigation
        overrides={{
          Root: {
            style: () => STYLES.container(theme),
          },
        }}
      >
        <div className={css(STYLES.innerContainer(theme))}>
          <StyledNavigationList $align={ALIGN.left}>
            <If
              condition={
                authContext.isLoggedIn() &&
                screenSize.width <= theme.breakpoints.medium
              }
            >
              <StyledNavigationItem>
                <Menu
                  color={theme.colors.contentPrimary}
                  onClick={onOpenMenu}
                  size={24}
                />
              </StyledNavigationItem>
            </If>
          </StyledNavigationList>
          <StyledNavigationList $align={ALIGN.center} />
          <StyledNavigationList $align={ALIGN.right}>
            <StyledNavigationItem>
              <img src={logo} width={120} />
            </StyledNavigationItem>
            <If condition={authContext.isLoggedIn()}>
              <StyledNavigationItem>
                <NotificationWidget />
              </StyledNavigationItem>
            </If>
          </StyledNavigationList>
        </div>
      </HeaderNavigation>
      <Drawer
        anchor={ANCHOR.left}
        autoFocus
        isOpen={isMenuOpen}
        mountNode={document.querySelector("#modal-mount-node") as HTMLElement}
        onClose={() => setIsMenuOpen(false)}
        renderAll
      >
        <Sidebar onChange={() => setTimeout(() => setIsMenuOpen(false), 250)} />
      </Drawer>
    </React.Fragment>
  );
};

export default Header;
