import * as React from "react";

import { API } from "../../api";
import { AuthContext } from "../auth-provider";
import { ApiContextT } from "./types";

export const ApiContext = React.createContext<ApiContextT>({
  notifications: undefined,
  rankingCoins: undefined,
  ranks: undefined,
  userCommission: undefined,
});

export const ApiProvider = ({ children }: { children?: React.ReactNode }) => {
  const authContext = React.useContext(AuthContext);

  const [userCommission, setUserCommission] =
    React.useState<ApiContextT["userCommission"]>();
  const [ranks, setRanks] = React.useState<ApiContextT["ranks"]>();
  const [rankingCoins, setRankingCoins] =
    React.useState<ApiContextT["rankingCoins"]>();
  const [notificationsInterval, setNotificationsInterval] = React.useState<
    NodeJS.Timeout | undefined
  >(undefined);
  const [notifications, setNotifications] =
    React.useState<ApiContextT["notifications"]>();

  const fetchUserCommission = React.useCallback(async () => {
    const { data } = await API.userCommission();
    setUserCommission(data.userCommission);
  }, []);

  const fetchRanks = React.useCallback(async () => {
    const { data } = await API.ranks();
    setRanks(data.ranks);
  }, []);

  const fetchRankingCoins = React.useCallback(async () => {
    const { data } = await API.rankingCoins();
    setRankingCoins(data.ranking);
  }, []);

  const setupNotificationsInterval = React.useCallback(async () => {
    if (notificationsInterval) {
      clearInterval(notificationsInterval);
    }
    const interval = setInterval(() => fetchNotifications(), 6000 * 10 * 5);
    setNotificationsInterval(interval);
    fetchNotifications();
  }, [notificationsInterval]);

  const fetchNotifications = React.useCallback(async () => {
    const { data } = await API.getNotifications();
    setNotifications(data.notifications);
  }, []);

  const reset = React.useCallback(() => {
    if (notificationsInterval) {
      clearInterval(notificationsInterval);
    }
    setUserCommission(undefined);
    setRanks(undefined);
    setRankingCoins(undefined);
    setNotifications(undefined);
  }, [notificationsInterval]);

  React.useEffect(() => {
    if (authContext.isLoggedIn()) {
      fetchUserCommission();
      fetchRanks();
      fetchRankingCoins();
      setupNotificationsInterval();
    } else {
      reset();
    }
    return () => reset();
  }, [authContext.state.isLoggedIn]);

  return (
    <ApiContext.Provider
      value={{
        notifications,
        rankingCoins,
        ranks,
        userCommission,
      }}
    >
      {children}
    </ApiContext.Provider>
  );
};
