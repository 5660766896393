import { useStyletron } from "baseui";
import * as React from "react";

import { useScreenSize } from "../../hooks/use-screen-size";
import { Column, Row } from "../containers";
import Footer from "../footer";
import Header from "../header";
import If from "../if";
import Sidebar from "../sidebar";
import { STYLES } from "./styles";

const MasterPage = ({
  children,
  hasSidebar,
}: {
  children?: React.ReactNode;
  hasSidebar: boolean;
}): React.ReactNode => {
  const [css, theme] = useStyletron();
  const screenSize = useScreenSize();

  return (
    <React.Fragment>
      <Header />
      <Row className={css(STYLES.container(theme))}>
        <If
          condition={hasSidebar && screenSize.width > theme.breakpoints.medium}
        >
          <Column className={css(STYLES.menuContainer)}>
            <Sidebar />
          </Column>
        </If>
        <Column className={css(STYLES.contentContainer(theme))}>
          {children}
        </Column>
      </Row>
      <Footer />
    </React.Fragment>
  );
};

export default MasterPage;
