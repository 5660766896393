import { Theme } from "baseui";

export const STYLES = {
  contentColumn: {
    width: "100%",
  },
  contentParagraph: (theme: Theme) =>
    ({
      color: theme.colors.contentSecondary,
      marginBottom: 0,
      marginTop: theme.sizing.scale0,
    }) as const,
  contentRow: (theme: Theme) =>
    ({
      alignItems: "center",
      gap: theme.sizing.scale600,
      justifyContent: "space-between",
      width: "100%",
    }) as const,
  dotContainer: (theme: Theme) =>
    ({
      position: "absolute",
      right: theme.sizing.scale200,
      top: "0",
    }) as const,
  listContainer: (theme: Theme) =>
    ({
      background: "#fff",
      borderRadiusTopLeft: theme.sizing.scale300,
      borderRadiusTopRight: theme.sizing.scale300,
      margin: 0,
      maxHeight: "390px",
      overflowY: "auto",
      padding: 0,
      width: "100%",
    }) as const,
  listContainerWrapper: (theme: Theme) =>
    ({
      borderRadius: theme.sizing.scale300,
      overflow: "hidden",
      width: "375px",
    }) as const,
  noContentContainer: (theme: Theme) =>
    ({
      background: "#fff",
      borderRadius: theme.sizing.scale300,
      padding: theme.sizing.scale900,
    }) as const,
  widgetEntryPointContainer: {
    position: "relative",
  } as const,
};
