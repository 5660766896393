import { Theme } from "baseui";

export const STYLES = {
  button: (theme: Theme, isFullWidth: boolean) =>
    ({
      marginTop: theme.sizing.scale400,
      maxWidth: isFullWidth ? "100%" : "50%",
      minWidth: "fit-content",
    }) as const,
  formContainer: (theme: Theme) => ({
    alignItems: "center",
    gap: theme.sizing.scale600,
  }),
  formContainerChildren: {
    minWidth: "50%",
  },
  heading: (theme: Theme) =>
    ({
      margin: `0 0 ${theme.sizing.scale800} 0`,
    }) as const,
  headingRow: (theme: Theme) =>
    ({
      marginBottom: theme.sizing.scale600,
    }) as const,
  imageContainer: (theme: Theme, isFullWidth: boolean) =>
    ({
      maxWidth: isFullWidth ? `calc(100% - ${theme.sizing.scale900})` : "400px",
      padding: theme.sizing.scale600,
      width: isFullWidth ? "100%" : "50%",
    }) as const,
  img: {
    aspectRatio: "1",
    objectFit: "contain",
    width: "100%",
  } as const,
  modalContainer: (theme: Theme) => ({
    gap: theme.sizing.scale600,
  }),
  textContainer: (theme: Theme) =>
    ({
      maxWidth: `calc(100% - ${theme.sizing.scale900})`,
      padding: theme.sizing.scale600,
      width: "100%",
    }) as const,
};
