import { useStyletron } from "baseui";
import { Button, KIND, SIZE } from "baseui/button";
import { ANCHOR, Drawer } from "baseui/drawer";
import { FormControl } from "baseui/form-control";
import { Input } from "baseui/input";
import { Select } from "baseui/select";
import { Tag } from "baseui/tag";
import * as React from "react";

import { BUTTON_FULL_WIDTH_OVERRIDE } from "../components/common-overrides";
import { Column } from "../components/containers";
import { FilterIcon } from "../components/icons";
import { STYLES } from "../pages/purchases/styles";
import {
  CampaignAllowListT,
  useCampaignAllowList,
} from "./use-campaign-allow-list";

export type FilterFormDefaultT = {
  defaultValue: string | undefined;
  isTagCloseable: boolean;
  key: string;
  label: string;
  options?: Array<CampaignAllowListT & { id: string; label: string }>;
  type: "INPUT" | "SELECT";
};

export type UseFilterT = {
  defaultForm: Array<FilterFormDefaultT>;
  onFilter: (form: object) => void;
  txtEntryPoint: string;
  txtSubmit: string;
};

const FilterTags = ({
  applyFilter,
  currentValues,
  defaultForm,
  setForm,
}: {
  applyFilter?: () => void;
  currentValues: object;
  defaultForm: Array<FilterFormDefaultT>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setForm?: (form: any) => any;
}) => {
  const reactId = React.useId();
  const campaignAllowListFilter = useCampaignAllowList();
  const [lastUpdate, setLastUpdate] = React.useState<number | undefined>(
    undefined,
  );

  React.useEffect(() => {
    if (lastUpdate) {
      setLastUpdate(undefined);
      if (applyFilter) {
        applyFilter();
      }
    }
  }, [lastUpdate, applyFilter]);

  const tags = defaultForm.map((defaultField, index) => {
    // @ts-expect-error keys
    const currentValue = currentValues[defaultField.key];
    const options = defaultField.options?.filter(campaignAllowListFilter);
    const option =
      defaultField.type === "SELECT"
        ? options?.find((opt) => opt.id === currentValue)
        : undefined;
    if (defaultField.defaultValue === currentValue) {
      return undefined;
    }
    return (
      <Tag
        closeable={
          setForm !== undefined &&
          applyFilter !== undefined &&
          defaultField.isTagCloseable
        }
        key={`filter-tags-${reactId}-${defaultField.key}-${index}`}
        onActionClick={() => {
          if (setForm) {
            setForm({
              ...currentValues,
              [defaultField.key]: defaultField.defaultValue,
            });
            setLastUpdate(+new Date());
          }
        }}
      >
        {`${defaultField.label}: ${option?.label || currentValue}`}
      </Tag>
    );
  });

  if (!tags || tags?.filter((tag) => tag !== undefined).length === 0) {
    return undefined;
  }

  return tags?.filter((tag) => tag !== undefined);
};

const Filter = ({
  applyFilter,
  defaultForm,
  form,
  isOpen,
  setForm,
  setIsOpen,
  txtEntryPoint,
  txtSubmit,
}: {
  applyFilter?: () => void;
  defaultForm: Array<FilterFormDefaultT>;
  form: object;
  isOpen: boolean;
  setForm: (form: object) => void;
  setIsOpen: (isOpen: boolean) => void;
  txtEntryPoint: string;
  txtSubmit: string;
}) => {
  const reactId = React.useId();
  const [css, theme] = useStyletron();
  const campaignAllowListFilter = useCampaignAllowList();

  const getField = React.useCallback(
    (field: FilterFormDefaultT) => {
      const fieldKey = `filter-field-${field.key}-${field.type}-${reactId}`;
      if (field.type === "INPUT") {
        return (
          <FormControl key={fieldKey} label={field.label}>
            <Input
              onChange={(e) =>
                // @ts-expect-error any
                setForm((form) => ({
                  ...form,
                  [field.key]: e.target.value,
                }))
              }
              // @ts-expect-error key
              value={form[field.key]}
            />
          </FormControl>
        );
      }
      if (field.type === "SELECT") {
        const options = field.options?.filter(campaignAllowListFilter);
        const selected = options?.find(
          // @ts-expect-error key
          (opt) => opt.id === form[field.key],
        );
        const baseOption = options?.find(
          (opt) => opt.id === field.defaultValue,
        );
        return (
          <FormControl key={fieldKey} label={field.label}>
            <Select
              onChange={(params) =>
                // @ts-expect-error any
                setForm((form) => ({
                  ...form,
                  [field.key]: params?.value[0]?.id,
                }))
              }
              options={options}
              placeholder={"Selecione..."}
              // @ts-expect-error key
              value={selected ? [selected] : baseOption}
            />
          </FormControl>
        );
      }
    },
    [reactId, form, campaignAllowListFilter],
  );

  // must return an array with filter entry point and filter tags
  return (
    <Column className={css(STYLES.filterContainer(theme))} key={React.useId()}>
      <Button
        kind={KIND.secondary}
        onClick={() => setIsOpen(true)}
        size={SIZE.compact}
        startEnhancer={() => <FilterIcon />}
      >
        {txtEntryPoint}
      </Button>
      <Drawer
        anchor={ANCHOR.left}
        animate={true}
        autoFocus
        isOpen={isOpen}
        mountNode={document.querySelector("#modal-mount-node") as HTMLElement}
        onClose={() => setIsOpen(false)}
      >
        {defaultForm.map((field) => getField(field))}
        <Button onClick={applyFilter} overrides={BUTTON_FULL_WIDTH_OVERRIDE}>
          {txtSubmit}
        </Button>
      </Drawer>
    </Column>
  );
};

const useFilter = ({
  defaultForm,
  onFilter,
  txtEntryPoint,
  txtSubmit,
}: UseFilterT) => {
  const [isOpen, setIsOpen] = React.useState(false);

  // before applying filter
  const [form, setForm] = React.useState(
    defaultForm.reduce((previousValue, defaultField) => {
      return {
        ...previousValue,
        [defaultField.key]: defaultField.defaultValue,
      };
    }, {}),
  );

  // applied filter is required to use filter-tags
  const [appliedForm, setAppliedForm] = React.useState(form);

  // apply filter function required to use filter-tags
  const applyFilter = React.useCallback(() => {
    setIsOpen(false);
    setAppliedForm(form);
    onFilter(form);
  }, [appliedForm, form, onFilter]);

  return [
    Filter({
      applyFilter,
      defaultForm,
      form,
      isOpen,
      setForm,
      setIsOpen,
      txtEntryPoint,
      txtSubmit,
    }),
    FilterTags({
      applyFilter,
      currentValues: appliedForm,
      defaultForm,
      setForm,
    }),
  ];
};

export default useFilter;
