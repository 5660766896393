import { useStyletron } from "baseui";
import { Button } from "baseui/button";
import {
  LabelMedium,
  ParagraphSmall,
  ParagraphXSmall,
} from "baseui/typography";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { ShoppingItemT } from "../../../api/shopping/types";
import { settings } from "../../../app/settings";
import { Column } from "../../../components/containers";
import { STYLES } from "./styles";

const ShoppingItemCard = ({
  item,
}: {
  item: ShoppingItemT;
}): React.ReactNode => {
  const [css, theme] = useStyletron();
  const { t: translate } = useTranslation();
  const navigate = useNavigate();

  const openDetail = React.useCallback(() => {
    navigate(`/shopping-item-detail/${item.id}`, { replace: false });
  }, [item, navigate]);

  return (
    <Column className={css(STYLES.container(theme))}>
      <img
        className={css(STYLES.img)}
        src={`${settings.aws.s3.BASE_URL}/${item.image}`}
      />
      <Column className={css(STYLES.content(theme))}>
        <LabelMedium className={css(STYLES.heading)}>
          {translate("shopping.item.points", item)}
        </LabelMedium>
        <ParagraphSmall className={css(STYLES.paragraph)}>
          {item.name}
        </ParagraphSmall>
        <ParagraphXSmall
          className={css(STYLES.paragraphSecondary(theme))}
          color={theme.colors.gray600}
        >
          {translate("shopping.item.stock", item)}
        </ParagraphXSmall>
        <Button
          className={css(STYLES.button(theme))}
          kind="secondary"
          onClick={() => openDetail()}
          size="compact"
        >
          {translate("shopping.item.button")}
        </Button>
      </Column>
    </Column>
  );
};

export default ShoppingItemCard;
