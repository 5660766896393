import { Theme } from "baseui";

export const STYLES = {
  contentColumn: {
    width: "100%",
  },
  contentParagraph: (theme: Theme) =>
    ({
      color: theme.colors.contentSecondary,
      marginBottom: 0,
      marginTop: theme.sizing.scale0,
    }) as const,
  contentRow: (theme: Theme) =>
    ({
      alignItems: "center",
      gap: theme.sizing.scale600,
      justifyContent: "space-between",
      width: "100%",
    }) as const,
  iframe: (height: number) => ({
    border: "unset",
    height: `${height}px`,
    width: "100%",
  }),
  listContainer: (theme: Theme) =>
    ({
      background: "#fff",
      borderRadius: theme.sizing.scale300,
      margin: 0,
      overflow: "hidden",
      padding: 0,
      width: "100%",
    }) as const,
};
