import { Theme } from "baseui";

import { CustomTheme } from "../../app/theme";

export const STYLES = {
  container: (theme: Theme) =>
    ({
      backgroundColor: (theme as CustomTheme).custom.headerColor,
      borderBottomColor: theme.colors.gray100,
      margin: "0 auto",
      maxWidth: "1920px",
    }) as const,
  innerContainer: (theme: Theme) => ({
    display: "flex",
    margin: "0 auto",
    maxWidth: "1920px",
    padding: `${theme.sizing.scale600}`,
    width: `calc(100% - ${theme.sizing.scale900})`,
  }),
  links: {
    cursor: "pointer",
  },
};
