import { Theme } from "baseui";

import { CustomTheme } from "../../app/theme";

export const STYLES = {
  container: (theme: Theme) => ({
    backgroundColor: (theme as CustomTheme).custom.foregroundColor,
    margin: "0 auto",
    maxWidth: "1920px",
    width: "100%",
  }),
  contentContainer: (theme: Theme) => ({
    maxWidth: "calc(100 % - 32px)",
    minHeight: "calc(100vh - 131.5px - 240px)",
    overflow: "hidden",
    padding: `${theme.sizing.scale600} ${theme.sizing.scale600}`,
    width: "100%",
  }),
  menuContainer: {
    borderRight: "1px solid #E2E2E2",
    maxWidth: "320px",
    overflow: "hidden",
    width: "30vw",
  },
};
