import { useStyletron } from "baseui";
import { Avatar } from "baseui/avatar";
import { Button, SIZE } from "baseui/button";
import { HeadingSmall } from "baseui/typography";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

import { CustomTheme } from "../../app/theme";
import { ApiContext } from "../../providers/api-provider";
import { AuthContext } from "../../providers/auth-provider";
import { Row } from "../containers";
import {
  HistoryIcon,
  HomeIcon,
  MoneyIcon,
  PersonIcon,
  SchoolIcon,
} from "../icons";
import If from "../if";
import { Navigation, NavigationSection } from "./navigation";
import { STYLES } from "./styles";
import { WidgetItem, Widgets } from "./widgets";

const Sidebar = ({ onChange }: { onChange?: () => void }): React.ReactNode => {
  const authContext = React.useContext(AuthContext);
  const apiContext = React.useContext(ApiContext);

  const [css, theme] = useStyletron();
  const navigate = useNavigate();
  const [activeItemId, setActiveItemId] = React.useState(
    window.location.pathname,
  );
  const { navigationIconColor } = (theme as CustomTheme).custom;
  const { t: translate } = useTranslation();

  const onLogoff = React.useCallback(() => {
    authContext.destroy();
  }, [authContext]);

  const getNameFormatted = React.useCallback(
    (removeSpecial: boolean) => {
      let name = authContext.userState?.name;
      if (removeSpecial) {
        name = name?.replace(/[^\w\s]/gi, "").trim();
      }
      const names = name?.split(" ") || [""];
      if (names.length >= 2) {
        return `${names[0]} ${names[names.length - 1]}`;
      }
      return names[0];
    },
    [authContext],
  );

  const widgetItems = React.useMemo(() => {
    const widgets: Array<WidgetItem> = [];
    if (apiContext.userCommission) {
      widgets.push({
        itemId: "/commissions",
        subtitle: translate("sidebar.widget.commission_subtitle"),
        title: `R$ ${apiContext.userCommission?.total}`,
      });
    }
    if (apiContext.ranks && apiContext.rankingCoins) {
      const points = apiContext.rankingCoins.myPoints?.points || 0;
      const myRanking =
        apiContext.ranks
          .filter((rank) => rank.minimalPoints <= points)
          ?.sort((a, b) => b.minimalPoints - a.minimalPoints)[0]?.name || "-";
      widgets.push({
        includedCampaigns: ["disabled-to-all"], // disabled for now
        itemId: "/ranking",
        subtitle: translate("sidebar.widget.ranking_subtitle"),
        title: `${myRanking}`,
      });
    }
    return widgets;
  }, [apiContext.userCommission, apiContext.ranks, apiContext.rankingCoins]);

  const navigationSections: Array<NavigationSection> = [
    {
      items: [
        {
          icon: <HomeIcon color={navigationIconColor} />,
          itemId: "/home",
          title: translate("sidebar.navigation.home"),
        },
        {
          icon: <SchoolIcon color={navigationIconColor} />,
          itemId: "/referral",
          title: translate("sidebar.navigation.courses"),
        },
        {
          icon: <MoneyIcon color={navigationIconColor} />,
          itemId: "/commissions",
          title: translate("sidebar.navigation.commissions"),
        },
        {
          icon: <HistoryIcon color={navigationIconColor} />,
          itemId: "/purchases",
          title: translate("sidebar.navigation.purchases"),
        },
        {
          icon: <PersonIcon color={navigationIconColor} />,
          itemId: "/profile",
          title: translate("sidebar.navigation.profile"),
        },
      ],
    },
    {
      items: [
        {
          icon: <MoneyIcon color={navigationIconColor} />,
          itemId: "/points",
          title: translate("sidebar.navigation.points"),
        },
        {
          icon: <HomeIcon color={navigationIconColor} />,
          itemId: "/challenges",
          title: translate("sidebar.navigation.challenges"),
        },
        {
          icon: <SchoolIcon color={navigationIconColor} />,
          itemId: "/training",
          title: translate("sidebar.navigation.training"),
        },
        {
          icon: <HistoryIcon color={navigationIconColor} />,
          itemId: "/shopping",
          title: translate("sidebar.navigation.shopping"),
        },
      ],
      title: translate("sidebar.navigation.journey_section"),
    },
  ];

  return (
    <React.Fragment>
      <Row className={css(STYLES.avatarWrapper(theme))}>
        <HeadingSmall className={css(STYLES.avatarWelcomeText)}>
          {translate("sidebar.welcome", { name: getNameFormatted(false) })}
        </HeadingSmall>
        <Avatar
          name={getNameFormatted(true)}
          overrides={{
            Root: {
              style: () => ({
                backgroundColor: navigationIconColor,
              }),
            },
          }}
          size="scale950"
        />
      </Row>
      <Widgets
        items={widgetItems}
        onChange={({ item }) => {
          const route = item.itemId;
          if (!route) {
            return;
          }
          navigate(route, { replace: false });
          setActiveItemId(route);
          onChange && onChange();
        }}
      />
      <Navigation
        activeItemId={activeItemId}
        onChange={({ item }) => {
          const route = item.itemId;
          if (!route) {
            return;
          }
          navigate(route, { replace: false });
          setActiveItemId(route);
          onChange && onChange();
        }}
        sections={navigationSections}
      />
      <If condition={authContext.isLoggedIn()}>
        <div className={css(STYLES.extraButtonsWrapper(theme))}>
          <Button onClick={onLogoff} size={SIZE.mini}>
            {translate("sidebar.logout")}
          </Button>
        </div>
      </If>
    </React.Fragment>
  );
};

export default Sidebar;
